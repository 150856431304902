import React, { useEffect, useState, useRef } from "react";
import "./User-roles.scss";
import { RiContactsFill } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AllRole ,rolesuspend ,RoleActive ,AllDropdown} from "./Api/Api";
import { Link } from "react-router-dom";
import { Button ,Table , Space ,Input,Menu,Dropdown } from 'antd';
import { MdEdit } from "react-icons/md";
import {  MoreOutlined ,CheckOutlined } from '@ant-design/icons';
import { RxCross2 } from "react-icons/rx";
import {Tag } from 'antd';
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import {  } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words'

const User_roles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    tableData:[],
    filterName:"Please Select"
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 3,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const fetchData = () => {
    AllRole()
      .then((res) => {
        const data = res?.data?.data?.map((item, index) => ({
          id: item.id,
          name: item.name,
          description: item.description,
          status: item.status,
          key: index, 
          _id: item._id,
        })) || [];
        setState((prevState) => ({
          ...prevState,
          tableData: data,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleDropdown = () => {
    AllDropdown()
      .then((res) => {
        console.log(res, 937363);
        const data = res?.data?.data?.map((item) => ({
          name: item.label,
          key: item.value,
          status: true,
          _id: item._id,
        }));
        setState((prevState) => ({
          ...prevState,
          tableData: data,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const RoleSuspendFunc = (id) => {
    rolesuspend(id)
      .then((res) => {
        console.log(res.data.message, 786777);
        setState((prevState) => ({
          ...prevState,
          tableData: prevState.tableData.map((role) =>
            role._id === id ? { ...role, status: "Suspend" } : role
          ),
        }));
        fetchData(); 
      })
      .catch((error) => {
        console.error("Error suspending role:", error);
      });
  };
  
  const RoleActiveFunc = (id) => {
    RoleActive(id)
      .then((res) => {
        console.log(res.data.message, 786777);
        setState((prevState) => ({
          ...prevState,
          tableData: prevState.tableData.map((role) =>
            role._id === id ? { ...role, status: "Active" } : role
          ),
        }));
        fetchData(); 
      })
      .catch((error) => {
        console.error("Error activating role:", error);
      });
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps('name'),

    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps('description'),

    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => (
        value ? <Tag color="blue">Active</Tag> : <Tag color="red">Suspend</Tag> 
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <Link to={`/Settings/User_roles/CreateRole/${record._id}`}>
                  <div>
                    <MdEdit style={{ fontSize: "15px" }} /> Edit
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item onClick={() => RoleSuspendFunc(record._id)} key="2">
                <div>
                  <RxCross2 style={{ fontSize: "15px" }} /> Suspend
                </div>
              </Menu.Item>
              <Menu.Item onClick={() => RoleActiveFunc(record._id)} key="3">
                <div>
                  <CheckOutlined style={{ fontSize: "15px" }} /> Active
                </div>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <MoreOutlined style={{ fontSize: "14px" }} />
          </a>
        </Dropdown>
      ),
    },
  ];
  const items = [
    {
      key: 'Create role',
      label: 'Create role',
      onClick: () => {
        navigate("/Settings/User_roles/CreateRole"); 
        setState((prevState) => ({
          ...prevState,
        }));
      },
    },
    {
      key: 'active',
      label: 'Active Roles',
      onClick: () => {
        handleDropdown();
        setState((prevState) => ({
          ...prevState,
          filterName: "Active Roles",
        }));
      },
    },
    {
      key: 'inactive',
      label: 'Clear Roles',
      onClick: () => {
        fetchData();
        setState((prevState) => ({
          ...prevState,
          filterName: "Clear Roles",
        }));
      },
    },
  ];
  
  return (
    <>
      <div className="synctic-module-container">
      <div 
  className="cards-box" 
  style={{
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  }}
>
  <div>
    <div className="synctic-module-def">
      <div 
        style={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        {/* Icon */}
        <RiContactsFill 
          className="icon" 
          style={{
            fontSize: "24px", 
            color: "#1890ff", 
          }} 
        />

        {/* Heading and Dropdown */}
        <div 
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          {/* Heading */}
          <div 
            className="heading" 
            style={{
              fontSize: "20px", 
              fontWeight: "600", 
              color: "#333", 
              lineHeight: "1.5",
            }}
          >
            {t("User-And-Roles")}
          </div>

          {/* Dropdown */}
          <Dropdown
            overlay={
              <Menu items={items} />
            }
            placement="bottomCenter"
          >
            <Button 
              style={{
                width: "150px",
                padding: "6px 12px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {state.filterName || 'Please Select'}
              <CaretDownOutlined style={{ fontSize: "16px", marginLeft: "8px" }} />
            </Button>
          </Dropdown>
        </div>
      </div>

      {/* Subheading */}
      <div 
        className="sub-heading" 
        style={{
          fontSize: "14px",
          fontWeight: "400",
          color: "#666",
          marginTop: "10px",
          lineHeight: "1.6",
        }}
      >
        Assign roles and set permissions to ensure the right people have access to the right content.
        With this feature, you can quickly and securely manage access to your team's workflows.
      </div>
    </div>
  </div>
</div>

        <Table
          style={{marginTop:"2%"}}
          columns={columns}
          dataSource={state.tableData}
          pagination={pagination}
          onChange={(page) => {
            setPagination(page);
          }}
        />

      </div>
    </>
  );
};

export default User_roles;
