import React, { useEffect, useState ,useRef} from 'react'
import { Table,Input} from 'antd';
import { Select, Space ,Button,Dropdown } from 'antd';
import { useParams ,useNavigate } from 'react-router-dom';
import {updateTeam , getTeam ,allAccounts,getAllTeams,AddMember , removeTeamMmber} from "./API/API";
import { CaretDownOutlined } from "@ant-design/icons";
import openNotificationWithIcon from "../../../Modules/ResuseableComponnets/Toaster";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
const TeamInfo = () => {
  const [state, setstate] = useState({
    name: "",
    description: "",
    AllTeam: [],
    accounts: [],
    selectedUsers: [], 
    filterName: "Please Select",
    teamId:"",
    TableData:[]
  });
  const navigate = useNavigate();
  const { Option } = Select;
  const { id } = useParams();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 3,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
 
  const GetAllTeam = (id) => {
    getTeam(id)
      .then((res) => {
        const userid = res?.data?.data?.users.map((user) => user.id);
        const userEmails = res?.data?.data?.users.map((user) => ({
          id: user.id,
          email: user.email,
        }));        
        setstate((prevState) => ({
          ...prevState,
          name: res.data.data.name,
          selectedUsers: userid,
          TableData: userEmails
        }));
      })
      .catch((error) => {
        console.error('Error fetching team data:', error);
      });
  };
    
    
    const AccountAllFunc = () => {
      allAccounts()
        .then((res) => {
          const data = res.data.data.rows.map((item) => ({
            email: item.email,
            id: item._id,
          }))
    
          setstate({
            accounts: data, 
          });
        })
        .catch((error) => {
          console.error('Error fetching team data:', error);
        });
    };
    
    const GetAllfunc = () => {
      getAllTeams()
        .then((res) => {
          const allTeams = res.data.data;
    
          if (allTeams.length > 0) {
            const lastTeamName = allTeams[allTeams.length - 1]?.name;
            const lastId = allTeams[allTeams.length - 1]?._id;
            console.log(lastId, 93873636);
            setstate((prevState) => ({
              ...prevState,
              name: lastTeamName,
              teamId: lastId,
            }));
    
            console.log("Last Team ID:", lastId);
          } else {
            console.log("No teams found");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    const UpdateFunc = (id) => {
      const data = {
        name: state.name,
        description: state.description || 'Default description',
        users: Array.isArray(state.selectedUsers) ? state.selectedUsers : [],
      };    
      updateTeam(id, data)
        .then((res) => {
          navigate("/Settings/Teams");
          if (res.data && res.data.message) {
                    openNotificationWithIcon(
                      "success",
                      "Success Message",
                      res.data.message
                    );
                  }
            setstate({
              ...state,
              name: data.name,
              selectedUsers: data.users,
            });
          
        })
        .catch((error) => {
               console.error("Error in activating team:", error);
               openNotificationWithIcon(
                 "error",
                 "Error Message",
                 error.response?.data?.message || "Please try again later."
               );
             })
    };
    const AddMemberFunc = () => {
      const MemberData = {
        teamId: state.teamId,
        users: Array.isArray(state.selectedUsers) ? state.selectedUsers : [],
      };
    
      AddMember(MemberData)
        .then((res) => {
          console.log(res, 988776);
          navigate("/Settings/Teams");
          if (res.data && res.data.message) {
                    openNotificationWithIcon(
                      "success",
                      "Success Message",
                      res.data.message
                    );
                  }
          setstate({
            selectedUsers: [],
          });
        })
        .catch((error) => {
          console.error("Error adding members:", error);
          openNotificationWithIcon(
            "error",
            "Error Message",
            error.response?.data?.message || "Please try again later."
          )
        });
    };
    

    const RemoveMemberFunc = () =>{
      const MemberData = {
        teamId: id,
        users: Array.isArray(state.selectedUsers) ? state.selectedUsers : [],
      };
    
      removeTeamMmber(MemberData)
        .then((res) => {
          navigate("/Settings/Teams");
          if (res.data && res.data.message) {
                    openNotificationWithIcon(
                      "success",
                      "Success Message",
                      res.data.message
                    );
                  }
          setstate({
            selectedUsers: [],
          });
        })
        .catch((error) => {
          openNotificationWithIcon(
            "error",
            "Error Message",
            error.response?.data?.message || "Please try again later."
          )       
         });
    }
    useEffect(() => {
      if (id) {
        GetAllTeam(id);
      } else {
        GetAllfunc();
      }
      AccountAllFunc();
    }, [id]);
    
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ];
  const items = [
    {
      key: 'update',
      label: 'Update',
      onClick: () => {
        if (id) {
          UpdateFunc(id);
          setstate((prevState) => ({ ...prevState, filterName: 'Update' }));
        }
      },
    },
    {
      key: 'Delete',
      label: 'Remove Member',
      onClick: () => {
        RemoveMemberFunc();
        setstate((prevState) => ({ ...prevState, filterName: 'Remove Member' }));
      },
    },
  ];
  return (
    <div className="synctic-module-container">
  {/* Header Section */}
  <div className="cards-box" style={{ borderRadius: '8px', padding: '12px', boxShadow: '0px 2px 4px rgba(0,0,0,0.1)' }}>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ fontSize: '20px', fontWeight: '600', color: '#333' }}>Team Info</div>
        {id ?
        (  <Dropdown 
        menu={{ items }} 
        placement="bottomRight" 
        trigger={['click']}
        >
        <Button>
          {state.filterName || "Please Select"} <CaretDownOutlined />
        </Button>
      </Dropdown>):
      (null
      )
      }
        <Button  type="primary" style={{display: !id ? "block" : "none"}} onClick={AddMemberFunc}>Submit</Button >
      </div>
    </div>
  </div>

  {/* Input and User Section */}
  <div className="cards-box" style={{ marginTop: '15px', background: '#ffffff', borderRadius: '8px', padding: '12px', boxShadow: '0px 2px 4px rgba(0,0,0,0.1)' }}>
    <Input
      placeholder="Enter Team Name"
      style={{
        marginTop: '10px',
        borderRadius: '6px',
        border: '1px solid #d9d9d9',
      }}
      value={state.name}
      onChange={(e) => setstate({ ...state, name: e.target.value })}
    />

    {/* Add Users Section */}
    <div style={{ fontSize: '18px', marginTop: '15px', fontWeight: '600', color: '#444' }}>Add Users</div>
    <Space style={{ width: '100%', marginTop: '10px' }} direction="vertical">
  <Select
    mode="multiple"
    allowClear
    style={{
      width: '100%',
      borderRadius: '6px',
      padding: '5px',
    }}
    placeholder="Please select"
    value={state.selectedUsers || []}
    onChange={(value) => setstate(prev => ({ ...prev, selectedUsers: value }))}
    options={
      Array.isArray(state.accounts)
        ? state.accounts.map(({ email, id }) => ({
            label: email,
            value: id
          }))
        : []
    }
  />
</Space>

    {/* Team Users Section */}
    <div style={{ fontSize: '18px', marginTop: '20px', fontWeight: '600', color: '#444' }}>Team Users</div>
    <Table
      style={{
        marginTop: '10px',
        borderRadius: '6px',
        overflow: 'hidden',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
      }}
      columns={columns}
      dataSource={state.TableData}
      pagination={pagination}
          onChange={(page) => {
            setPagination(page);
          }}
    />
  </div>
</div>

  )
}

export default TeamInfo
