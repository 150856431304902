import { UploadOutlined }                      from '@ant-design/icons';
import { Button, Upload, Typography, message } from 'antd';
import React, { useState }                     from 'react'
import { useNavigate, useParams }              from 'react-router-dom';
import axios                                   from '../Authentication/headers';
import openNotificationWithIcon                from '../ResuseableComponnets/Toaster';
import Header                                  from "./Reuseable/Header";


const { Text, Link } = Typography;
const FileSegment = () => {
  const navigate = useNavigate()
  const [fileList, setFileList] = useState([]);
  const { id, name } = useParams()

  const handleChange = (info) => {
    const { fileList: newFileList } = info;

    // Limit to one file
    if (newFileList.length > 1) {
      newFileList.splice(1, newFileList.length - 1);
    }

    console.log(newFileList, "8654311111")

    setFileList(newFileList);
  };

  const handleSubmit = () => {
    if (fileList.length === 0) {
      message.warning("Please upload a file before submitting.");
      return;
    }

    const formData = new FormData();
    formData.append("list", fileList[0].originFileObj);
    formData.append("data", JSON.stringify({
      name: name,
      category: id
    }))

    axios.post("https://backend.tiebot.io/segment/create", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${ localStorage.getItem('token') }`,
      },
    }).then(response => {
      navigate("/segments")
      openNotificationWithIcon('success',
        'File has been uploaded',
        'File has been uploaded'
      )
    }).catch(err => {
        openNotificationWithIcon('error',
          'Error Message',
          err?.response?.data?.message
        )
      }
    )
  };
  return (
    <div className="synctic-module-container" style={ { padding: '20px' } }>
      <Header
        name="Segment Manger"
        summary="Back to summary"
        Back="Back"
        Continue="Submit"
        clickFunction={ () => handleSubmit() }
      />
      <div className='cards-box' style={ {
        marginTop: 10,
        border: '1px solid #E2E8F0',
        borderRadius: '0.375rem',
        padding: '1rem',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
      } }>
        <div style={ { marginBottom: '24px' } }>
          <h2
            style={ {
              fontSize: '1.125rem',
              fontWeight: '500',
              marginBottom: '0.5rem',
            } }
          >List Upload</h2>
          <p style={ { marginBottom: '16px', fontSize: '0.875rem' } }>Upload contacts list to whom you want to send a
            message.</p>
          <Text type="danger" style={ { display: 'block', marginBottom: '24px' } }>
            Note: Please upload a list of maximum 1000 contacts at a time.
          </Text>
        </div>

        <div style={ { marginBottom: '16px' } }>
          <Text style={ { display: 'block', marginBottom: '8px' } }>Select File to Upload</Text>
          <Upload
            maxCount={ 1 }
            fileList={ fileList }
            onChange={ handleChange }
            beforeUpload={ () => false }
            className="custom-upload"
            style={ { width: "100%", display: "block" } }
          >
            <Button icon={ <UploadOutlined/> } style={ { marginRight: "8px" } }>
              Choose File
            </Button>
          </Upload>
        </div>
        <div style={ { marginTop: '24px' } }>
          <Text style={ { display: 'block' } }>
            Download our{ ' ' }
            <Link href="#" style={ { color: '#1890ff' } }>
              sample CSV file
            </Link>{ ' ' }
            and replace the sample contacts with your own data.
          </Text>
        </div>
      </div>
    </div>
  )
}

export default FileSegment
