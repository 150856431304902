import { Input, Tooltip }         from "antd";
import React, { useState }        from "react";
import { IoIosInformationCircle } from "react-icons/io";
import { useNavigate }            from "react-router-dom";
import "./Segment.scss";
import WhatsAPPLgo                from "../../assets/svg/social-logos/whatsappLogo.png"
import Header                     from "./Reuseable/Header"

const SegmentForm = () => {
  const [state, setState] = useState({
    ActiveMode: "",
    name: "",
    category: ""
  });
  const navigate = useNavigate()
  const handleClick = (mode) => {
    setState({
      ...state,
      ActiveMode: state.ActiveMode === mode ? "" : mode
    })
  };

  const clickFunction = () => {
    state.ActiveMode === "list_upload" ?
      navigate(`/Segments/list_upload/${ state.name }`) :
      navigate(`/segments/create/${ state.ActiveMode }/${ state.name }`)
  }

  return (
    <div className="synctic-module-container" style={ { padding: "20px" } }>
      <Header
        name="Segments"
        summary="Back to summary"
        Back="Back"
        Continue="Continue"
        clickFunction={ clickFunction }
      />

      <div
        className="cards-box-data"
        style={ {
          marginTop: 10,
        } }
      >
        <h2 className="NewheadingFx" style={ { fontSize: "1.125rem" } }>Channels</h2>
        <p className="pra">
          Choose channel on which you want to create segment.
        </p>
        <div style={ { display: "flex", gap: 10 } }>
          <img src={ WhatsAPPLgo } alt="WhatsAPPLgo" height={ 40 } width={ 40 }/>
          <h2 className="NewheadingFx" style={ { fontSize: "1.125rem", marginTop: '10px', } }>WhatsApp</h2>
        </div>
      </div>
      <div
        className="cards-box-data"
        style={ {
          marginTop: 10,
        } }
      >
        <h3 className="NewheadingFx" style={ { fontSize: "1.125rem" } }>Name</h3>
        <p className="pra">Give you segment a name</p>
        <Input
          placeholder="Enter Name"
          onChange={ (e) => {
            setState({
              ...state, name: e.target.value
            })
          } }
          alt=""
          style={ { width: "50%" } }
        />
      </div>


      {/* this is tablist data */ }

      <div className="cards-box-data" style={ { marginTop: 10 } }>
        <div style={ { marginBottom: "8px", color: "#4B5563" } }>
          <h2 className="headingFx" style={ { fontSize: "1.125rem" } }>Category</h2>
          <p className="pra">
            Choose what category of segment you want to create
          </p>
        </div>
        <div style={ { display: "flex", justifyContent: "space-between", gap: 10 } }>
          <div
            className="cards-box-data"
            style={ {
              width: "35%",
              backgroundColor: state.ActiveMode === "entire_audience" ? "#10163a" : "white",
              color: state.ActiveMode === "entire_audience" ? "white" : "black",
            } }
            onClick={ () => handleClick("entire_audience") }
          >
            <div style={ { display: "flex", justifyContent: "space-between" } }>
              <span>Entire audience</span>
              <Tooltip title="Hellow World">
                <div>
                  <IoIosInformationCircle style={ { fontSize: 20 } }/>
                </div>
              </Tooltip>
            </div>
          </div>

          <div
            className="cards-box-data"
            style={ {
              width: "30%",
              backgroundColor: state.ActiveMode === "active_last_24_hours" ? "#10163a" : "white",
              color: state.ActiveMode === "active_last_24_hours" ? "white" : "black",
            } }
          >
            <div
              style={ { display: "flex", justifyContent: "space-between" } }
              onClick={ () => handleClick("active_last_24_hours") }
            >
              <span>Active last 24 hours (free)</span>
              <Tooltip title="Hellow World">
                <div>
                  <IoIosInformationCircle style={ { fontSize: 20 } }/>
                </div>
              </Tooltip>
            </div>
          </div>

          <div
            className="cards-box-data"
            style={ {
              width: "35%",
              backgroundColor: state.ActiveMode === "list_upload" ? "#10163a" : "white",
              color: state.ActiveMode === "list_upload" ? "white" : "black",
            } }
            onClick={ () => {
              handleClick("list_upload")
            } }>
            <div style={ { display: "flex", justifyContent: "space-between" } }>
              <span>List Upload</span>
              <Tooltip title="Hellow World">
                <div>
                  <IoIosInformationCircle style={ { fontSize: 20 } }/>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SegmentForm;
