import React, {useEffect, useRef, useState} from 'react';
import {Input, Button, Space, Table, Modal, Dropdown, Menu} from 'antd';
import {MoreOutlined, SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import EditIcon from '@mui/icons-material/Edit';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import DoneIcon from "@mui/icons-material/Done";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader                                                                               from ".././ResuseableComponnets/Loader";
import { getAllSegments, activateSegment, deActivateSegment, deleteSegment } from './APIS/Apis';
import moment from 'moment';
const AllSegments = (props) => {
  const [state, setState] = useState({
    count : 0,
    openDialogue: false,
    name: "",
    isLoader: false,
    data: [],
    selectedData: null,
    mode: "",
    offset:1
  })
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total : 0
  })

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const navigate = useNavigate()

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };


  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      ...getColumnSearchProps("Name"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      ...getColumnSearchProps("category"),
    },
    {
      title: "Update At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      ...getColumnSearchProps("updatedAt"),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
    },
  ];
  

  const updatedArray = state?.data?.map((obj) => ({
    ...obj,
    'status': obj.status ? "Active" : "De-activated",
    action: (
      <Dropdown overlay={(
        <Menu>
          <Menu.Item key="1">
            <div onClick={() => setState({
              ...state,
              openDialogue: true,
              selectedData: obj,
              mode: "activate"
            })}>
              <DoneIcon/> Activate
            </div>
          </Menu.Item>
          <Menu.Item key="2">
            <div onClick={() => setState({
              ...state,
              openDialogue: true,
              selectedData: obj,
              mode: "de-activate"
            })}>
              <RemoveIcon/> De-activate
            </div>
          </Menu.Item>
          <Menu.Item key="3">
            <div onClick={() => setState({
              ...state,
              openDialogue: true,
              selectedData: obj,
              mode: "delete"
            })}>
              <DeleteIcon/> Delete
            </div>
          </Menu.Item>
          <Menu.Item key="4">
            <div onClick={() =>navigate(`/Segments/edit/${obj.id}`)}>
              <EditIcon/> Edit
            </div>
          </Menu.Item>
        </Menu>
      )} trigger={['click']}>
        <a onClick={(e) => e.preventDefault()}>
          <MoreOutlined style={{fontSize: '14px'}}/>
        </a>
      </Dropdown>
    )
  }))

  const getAllFunc = () => {
    setState({ ...state, isLoader: true });
  
    getAllSegments("pagination=1")
      .then((res) => {
        const NewData = res.data.data.rows.map((item, index) => ({
          key: index,
          Name: item.name,
          category: item.category,
          status: item.status,
          updatedAt: item.updatedAt ? moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') : null,
          id: item._id,
        }));
        setState({
          isLoader: false,
          data: NewData,
          mode: "",
        });
        setPagination({ ...pagination, total: res?.data?.data?.totalCount})
      })
      .catch((error) => {
        console.error("Error fetching segments:", error);
        setState({
          isLoader: false,
        });
      });
  };
  
  const deActivateFunc = ()=> {
    let id = state.selectedData.id
    deActivateSegment(id)
      .then(()=>getAllFunc())
      .catch(()=>setState({...state, isLoader: false}))
  }

  const activateFunc = ()=> {
    let id = state.selectedData.id
    activateSegment(id)
      .then(()=>getAllFunc())
      .catch(()=>setState({...state, isLoader: false}))
  }

  const deleteFunc = ()=> {
    let id = state.selectedData.id
    deleteSegment(id)
      .then(()=>getAllFunc())
      .catch(()=>setState({...state, isLoader: false}))
  }

  useEffect(() => {
    getAllFunc()
  }, []);



  const handleTableChange = (newPagination) => {
    let previousPage = pagination.current;
    let currentPage = newPagination.current;
    const pageSize = newPagination.pageSize;


    if (currentPage > previousPage) {
      const isLastPage = (state?.data?.length % pageSize === 0) && (state?.data?.length < state?.count);
      if (isLastPage) {
        const paginationNumber = Math.floor(state?.data?.length / 10) + 1; 
        let paginationQuery = `pagination=${paginationNumber}`;
  
        getAllSegments(paginationQuery)
          .then(res => {
            setState(prevState => ({
              ...prevState,
              data: [...prevState.data, ...res?.data?.data?.rows], 
              count: res?.data?.data?.totalCount, 
            }));
            setPagination(res?.data?.data?.totalCount)
          })
          .catch(err => {
            console.error('Error fetching data:', err);
          });
      }
    }
    setPagination({
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    });
  };  
  return (
    state.isLoader ? <Loader/> :
      <div className="synctic-module-container">
        <div className='cards-box' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className='heading'>Segmengts</div>
          <Button
            type="primary"
            style={{ float: 'right' }}
            onClick={()=>navigate("/segments/create")}
          >
            <IoIosAddCircleOutline
              style={{ fontSize: '15px', color: '#fff', marginRight: '12px', marginTop: '-2%' }}
            />
            Add Segment
          </Button>
        </div>

        <Modal
          title={ state.mode==="activate" ?
            "Are you sure you want to activate this?" :
            state.mode==="de-activate" ?
              "Are you sure you want to de-activate this?" :
              state.mode==="delete" ?
                "Are you sure you want to delete this?":
               ""
          }
          open={state.openDialogue}
          onCancel={() => setState({
            ...state,
            openDialogue: false,
            selectedData: null,
            mode:""
          })}
          onOk={() => {
            state.mode==="de-activate" ? deActivateFunc() :
              state.mode==="activate" ? activateFunc() :
                state.mode==="delete" && deleteFunc()
          }}
        />

        <Table
          pagination={pagination}
          onChange={handleTableChange}
          style={{ marginTop: 10 }}
          columns={columns}
          dataSource={updatedArray}
        />
      </div>
  );
}

export default AllSegments;
