import React, { useState ,useEffect } from 'react';
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import { AiOutlineApi } from "react-icons/ai";
import { Input, Select, Button, message } from 'antd';
import { MdDelete } from "react-icons/md";
import { apicreate , api , apiupdate} from "./Api/Api";
import "./Api.scss";
import {useParams} from 'react-router-dom'
import { Radio } from 'antd';
import {useNavigate} from "react-router-dom";
import validator from "validator";

const { TextArea } = Input;
const { Option } = Select;

  const Apiform = () => {
    const {id} = useParams();
  const [state, setState] = useState({
    name: "",
    description: "",
    type: "get",
    requestType: "param",
    URL: "",
    header: [{ key: "", value: "" }],
    responseKey: "",
    payload: "",
    error:"",
    loading:false
  });
  const navigate = useNavigate()

  const AllData = () => {
    if (id) {
      api(id)
        .then((res) => {
          const data = res.data?.data || [];
          setState({
            name: data.name || "",
            description: data.description || "",
            type: data.type || "",
            URL: data.url || "",
            header: data.header || "",
            requestType: data.requestType || "",
            responseKey: data.responseKey || "",
            payload: data.payload || "",

          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setState({});
        });
    } else {
      console.warn("ID is not defined");
    }
  };

  function structurePayload(payload){
    try {
      // Remove newlines and extra spaces if needed
      const cleanedPayload = payload.replace(/\\n/g, "").trim();
      // Parse the payload string into a JSON object
       return JSON.parse(cleanedPayload);
    } catch (error) {
      return false
     
    }
  }
  
  useEffect(()=>{
    AllData()
    },[])

  const handleSubmit = () => {
    let parsedPayload = structurePayload(state.payload);
    if(parsedPayload === false){
      message.error("Payload is not rightly structured")
      return;
    }

    const formData = {
      name: state.name,
      description: state.description,
      type: state.type,
      url: state.URL,
      requestType: state.requestType,
      header: state.header,
      payload: JSON.stringify(parsedPayload),
      responseKey:state.responseKey
    }; 

    apicreate(formData)
      .then(() => {
        navigate("/settings/api-management")
      })
      .catch(error => {
        console.error('Error creating API:', error);
      });
  };


  const handleHeaderInputChange = (index, field, value) => {
    const newHeaders = [...state.header];
    newHeaders[index][field] = value;
    setState({
      ...state,
      header: newHeaders
    });
  };

    const headerValidation = () => {
      return  state.header.find(item=>(
           item.key==='' || item.value===''
      ))}



  const addHeader = () => {
    setState({
      ...state,
      header: [...state.header, { key: "", value: "" }]
    });
  };

  const removeHeader = (index) => {
    const newHeaders = [...state.header];
    newHeaders.splice(index, 1);
    setState({
      ...state,
      header: newHeaders
    });
  };

  const handleUpdate = () => {
    let parsedPayload = structurePayload(state.payload);
    if(parsedPayload === false){
      message.error("Payload is not rightly structured")
      return;
    }

    
    const updateData = {
      name: state.name,
      description: state.description,
      type: state.type,
      url: state.URL,
      requestType: state.requestType,
      header: state.header,
      responseKey:state.responseKey,
      payload: JSON.stringify(parsedPayload),
    };

    apiupdate(id,updateData)
      .then(() => {
        navigate("/settings/api-management")
      })
      .catch(error => {
        console.error('Error creating API:', error);
      });
  };

  return (
    <div className='synctic-module-container'>
      <SettingsHeader
        heading="Create Custom Api"
        icon={<AiOutlineApi className='icon' />}
      />
      <div className='cards-box' style={{ marginTop: "1%" }}>
        <div className='main-name-api'>
          <div className='api-label-name'>API Name</div>
          <Input placeholder="Give your API a suitable name"
          size='large'
           className='api-name-input' value={state.name}
                 onChange={e => {
                   const inputValue = e.target.value;
                   const onlyTextRegex = /^[A-Za-z0-9_-\s]+$/; 
                   if (onlyTextRegex.test(inputValue) || inputValue === '') {
                     setState({...state, name: inputValue});
                   }
                 }}
            />
        </div>
        <div className='main-name-api'>
          <div className='api-label-name'>API Description</div>
          <TextArea rows={4} placeholder="Provide a brief description"
           className='api-name-input' value={state.description}
            onChange={e => setState({...state, description:e.target.value})}/>
        </div>

        <div className='main-name-api'>
          <div className='api-label-name'>API Type</div>
          <Select
           size='large'
            style={{ marginTop: "1%" }}
            value={state.type}
            onChange={e => setState({...state, type: e, dataType: e==='post'? 'payload' : 'param'})}
          >
            <Option value="get">Get</Option>
            <Option value="post">Post</Option>
          </Select>
        </div>
        <div>
          <div className='api-label-name mb-4'>Request Type</div>
          <div style={{ display: "flex", gap: "5px" }}>
              <Radio
                style={{ fontSize: "13px", fontWeight: "600",display: state.type === "post" ? "none" : null }}
                checked={state.requestType === "param"}
                onChange={(e) => setState({ ...state, requestType: e.target.value })}
                value="param"
              >
                Params
              </Radio>
              <Radio
                style={{ fontSize: "13px", fontWeight: "600",display: state.type === "post" ? "none" : null }}
                checked={state.requestType === "query"}
                onChange={(e) => setState({ ...state, requestType: e.target.value })}
                value="query"
              >
                Query
              </Radio>
              <Radio
                style={{ fontSize: "13px", fontWeight: "600",display: state.type === "get" ? "none" : null }}
                checked={state.requestType === "payload"}
                onChange={(e) => setState({ ...state, requestType: e.target.value })}
                value="payload"
              >
                Payload
              </Radio>
          </div>
        </div>
       
        <div className='main-name-api'>
          <div className='api-label-name'>API Endpoint URL</div>
          <Input placeholder="Provide the endpoint URL" 
          className='api-name-input'
          size='large'
                 value={state.URL}
                 onChange={e => {
                  const inputValue = e.target.value;
                  const urlValidator = validator.isURL(inputValue, {require_protocol: true});
                  setState({...state, URL: inputValue, error: urlValidator ? '' : 'Invalid URL' });
              }}
                 status={(state.URL && state.error) ? "error" : ""}
          />
          {state.URL && state.error && <p style={{fontSize: 12, color: "red"}}>{state.URL && state.error}</p>}

        </div>
        <div className='main-name-api'>
          <div className='api-label-name'>Response Key</div>
          <Input placeholder="Enter response key" 
           size='large'
          className='api-name-input'
            value={state.responseKey}
            onChange={e => {
              const inputValue = e.target.value;
              const onlyTextRegex = /^[A-Za-z\s]+$/; 
              if (onlyTextRegex.test(inputValue) || inputValue === '') {
                setState({...state, responseKey: inputValue});
              }
            }}          
            />
        </div>
        <div className='main-name-api' style={{display: state.type === "get" ? "none" : null }}
        >
        <div className='api-label-name'>Api Payload</div>
          <TextArea
            rows={4}
            placeholder="Provide a brief description"
            className="api-name-input"
            value={state.payload}
            onChange={(e) => {
              setState({
                ...state,
                payload: e.target.value,
              });
            }}
          />
        </div>

        <div className='main-name-api'>
          <div className='api-label-name'>API Headers</div>
              {state.header.map((header, index) => (
                <div key={index} style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                  <Input size='large'placeholder="Key" className='api-name-input' style={{ width: "40%" }} value={header.key} onChange={e => handleHeaderInputChange(index, "key", e.target.value)} />
                  <Input size='large'placeholder="Value" className='api-name-input' style={{ width: "67%" }} value={header.value} onChange={e => handleHeaderInputChange(index, "value", e.target.value)} />
                  <MdDelete style={{ fontSize: "30px", cursor: "pointer", marginTop: "1%", display: state.header.length ===1 ? 'none' : null }} onClick={() => removeHeader(index)} />
                </div>
              ))}
              <Button disabled={!!headerValidation()} type="primary" style={{ marginTop: "3%" ,width:"10%"}} onClick={addHeader}>Add</Button>
        </div>

        <div style={{ display: "flex", gap: "10px", marginTop: "2%" }}>
          <Button
          disabled={
            (state.type === "post" && (
                !state.name ||
                !state.description ||
                !!state.error ||
                !state.URL ||
                !state.responseKey
            )) ||
            (state.type === "get" && (
                !state.name ||
                !state.description ||
                !!state.error ||
                !state.URL ||
                !state.responseKey
            ))
        }
              type="primary" style={{ margin: "2% auto", width: "50%" }} onClick={id ? handleUpdate : handleSubmit}> {id ? "Update" : "Create"}</Button>
        </div>
      </div>
    </div>
  );
};

export default Apiform;
