import { Button } from 'antd';
import React, { useEffect, useState }         from 'react';
import "./Templates.scss";
import { useTranslation }                     from 'react-i18next';
import { HiOutlineDocumentText }              from "react-icons/hi";
import { useNavigate }                      from 'react-router-dom';
import openNotificationWithIcon               from '../../ResuseableComponnets/Toaster';
import {
  createTemplates,
  getAllTemplates,
  updateTemplates
}                                             from "./API";
import Table_Templates                        from "./TemplatesTable";

const Templates = () => {
  const [state, setState] = useState({
    defaultActiveTab: "1",
    activeTab: "",
    data: [],
    mode: "",
    name: "",
    body: "",
    isOpen: false,
    template_id: "",
    category: 'utility'
  })
  const { t } = useTranslation();
  const navigation=useNavigate()

  const updateTemplate = (data) => {
    setState({
      ...state,
      isOpen: true,
      name: data.title,
      body: data.body,
      template_id: data.id,
      mode: "update"
    })
  }
  const getTemplates = () => {
    getAllTemplates()
      .then(res => {
        setState({
          ...state,
          data: res.data.data.rows,
          activeTab: !!state.activeTab ? state.activeTab : state.defaultActiveTab,
          isOpen: false,
          name: "",
          body: "",
          mode: ""
        })
      })
      .catch(() => {
        openNotificationWithIcon('error', 'Error message', 'Error while getting templates.')
      })
  }

  useEffect(() => {
    getTemplates()
  }, []);

  return (
    <div className={ "synctic-module-container" }>
      <div className='Main-fluid '>
        <div className="cards-box" style={ {
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center"
        } }>
          <div>
            <div className='synctic-module-def' style={{display:'flex'}}>
              <HiOutlineDocumentText className="icon"/>
              <div className="heading">{ t("Templates") }</div>
            </div>
          </div>
          <Button
            style={{float:"right"}}
            type={ 'primary' }
            onClick={ () => navigation('/createTemplates') }
          >Add New</Button>
        </div>

      </div>
      <div className='cards-box' style={ { marginTop: 10 } }>
            <Table_Templates updateTemplate={ updateTemplate }  state={state} setState={setState}/>
      </div>
    </div>
  )
}

export default Templates;
