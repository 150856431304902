import axios from "../../Authentication/headers"

export function getAllSegments(query, pagination){
  return axios.get(`/segment/all?${query}`)
}

export function activateSegment(id){
  return axios.get(`/segment/activate/${id}`)
}

export function deActivateSegment(id){
  return axios.get(`/segment/deactivate/${id}`)
}

export function deleteSegment(id){
  return axios.get(`/segment/delete/${id}`)
}

export function getDropdown(){
  return axios.get(`/segment/dropdown`)
}

export function getChatBotMenu(){
  return axios.get(`/flow/dropdown`)
}

export function getAllTags(){
  return axios.get(`/tags/dropdown`)
}

export function getSegmentById(id){
  return axios.get(`/segment/${id}`)
}

