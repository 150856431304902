import React, { useRef, useState ,useEffect} from 'react';
import './Api.scss';
import { useTranslation } from 'react-i18next';
import { Dropdown,  Button } from 'antd';
import {CaretDownOutlined, SearchOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { apideactivateAll, Apiactiveall } from "./Api/Api";
import {IoIosAddCircleOutline} from 'react-icons/io';
  import { Input, Space, Table,Select } from 'antd';  
  import Highlighter from 'react-highlight-words';
  import { apiall , activedApi , DeactivedApi, Delete } from "./Api/Api" 
  import Loader from "../../ResuseableComponnets/Loader";
  import { Link } from 'react-router-dom'
  import { MdEdit } from "react-icons/md";
  import { Menu } from 'antd';
  import { CheckOutlined, CloseOutlined, MoreOutlined } from '@ant-design/icons';
  import { MdDelete } from "react-icons/md";
  import {   Modal } from 'antd';
  import { FaEye } from "react-icons/fa";
  import ApiSubmission from './ApiSubmission';


const Api_management = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    AllData: [],
    loading: false,
    filterName:'Please Select',
    ApiData: [],
    loading: true,
    modal:false,
    id:"",
  });

  const deactivate = () => {
    setState(prevState => ({ ...prevState, loading: true }));
  
    apideactivateAll()
      .then(() => {
        fetchData();
      })
      .catch(error => {
        console.error("Error deactivating:", error);
        setState(prevState => ({ ...prevState, loading: false }));
      });
  };
  
  const Active = () => {
    setState(prevState => ({ ...prevState, loading: true }));
  
    Apiactiveall() 
      .then((res) => {
        const activeData = res.data.data.map((item, index) => ({
          key: index,
          name: item.name,
          description: item.description,
          status: item.status ? "Active" : "Deactivated", 
        }));
  
        setState(prevState => ({
          ...prevState,
          ApiData: activeData, 
          loading: false,
        }));
      })
      .catch((error) => {
        console.error("Error activating API:", error);
        setState(prevState => ({ ...prevState, loading: false }));
      });
  };
  
  const modalOpen = () => {
    setState(prevState => ({
      ...prevState,
      modal: true
    }));
  };

  const fetchData = () => {
    setState({...state, loading:true})
    apiall()
        .then(response => {
            setState(prevState => ({
                ...prevState,
                id: response?.data?.data[0]?.id,
                ApiData:response.data.data.map((obj) => ({
                  ...obj,
                  'status': obj.status ? "Active" : "De-activated"})),
                loading:false
            }));
            setState(prevState => ({...prevState, loading: false}));
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setState(prevState => ({...prevState, loading: false}));
        });
}


const Activefuc = (id) => {
  activedApi(id)
      .then(() => fetchData())
      .catch(() => {
          setState(prevState => ({
              ...prevState,
              loading: false
          }));
      });
}

const DeActivefunc = (id) => {
  DeactivedApi(id)
      .then(() => fetchData())
      .catch(() => {
          setState(prevState => ({
              ...prevState,
              loading: false
          }));
      });
}

const Deletefunc = (id) => {
  Delete(id)
      .then(() => fetchData())
      .catch(() => {
          setState(prevState => ({
              ...prevState,
              loading: false
          }));
      });
}

useEffect(() => {
  fetchData();
}, []);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex )}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
             
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: t("Name"),
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: t("Description"),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t("Status"),
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => record.status || state.status 
    },
    {
      title: t("submission"),
      dataIndex: 'submission',
      render: (_, record) => (
        <a onClick={modalOpen}>
          <FaEye style={{ fontSize: '14px', cursor: 'pointer' }} />
        </a>
      )
    },   
    {
      title: t("Action"),
      key: 'action',
      render: (_, record) => (
        <Dropdown overlay={(
          <Menu>
            <Menu.Item onClick={() => Activefuc(record._id)} key="1">
              <div>
                <CheckOutlined /> {t("Active")} 
              </div>
            </Menu.Item>
            <Menu.Item onClick={() => DeActivefunc(record._id)} key="2">
              <div>
                <CloseOutlined /> {t("De-Activate")}
              </div>
            </Menu.Item>
            <Menu.Item key="3">
            <Link to={'/Settings/Api_mangement/Apiform/' + record._id}>
              <div>
                <MdEdit style={{fontSize:"15px"}}/> {t("Edit")}
              </div>
              </Link>
            </Menu.Item>
            <Menu.Item onClick={() => Deletefunc(record._id)} key="4">
              <div>
                 <MdDelete style={{fontSize:"15px",marginTop:"-1%"}}/> {t("Delete")}
              </div>
            </Menu.Item>
          </Menu>
        )} trigger={['click']}>
          <a>
            <MoreOutlined style={{ fontSize: '14px' }} />
          </a>
        </Dropdown>
      ),
    },
  ];
  return (
    <div className="synctic-module-container-tabs">
      <div className='cards-box' style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontSize: "20px", fontWeight: "500", opacity: ".7" }}>{t("Api")}</div>
          <p style={{ opacity: '.7' }}>{t("Manage your work description")}</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: "20px" }}>
            <Dropdown
            menu={{
              items: [
                { key: '1', label: 'Clear', onClick: () => fetchData() },
                { key: '2', label: 'Activated', onClick: () => Active() },
                { key: '3', label: 'DeActivate All', onClick: () => deactivate() },
              ],
            }}
            placement="bottom"
            trigger={["click"]}
          >
             <Button>
               {state.filterName || "Please Select"} 
                 <CaretDownOutlined />
                 </Button>                             
          </Dropdown>
            <Button
             type="primary"
             onClick={() => navigate("/Settings/Api_mangement/Apiform")}
             >
              <IoIosAddCircleOutline
                style={{fontSize: '15px', color: '#fff', marginRight: '12px', marginTop: '-2%'}}
                                />
               {t("New  Api Key")}
                </Button>

        </div>
      </div>
      <div className='main-access-key' style={{fontSize:"20px"}}>
     </div>
     <Modal
       title= "Api Submission"
        visible={state.modal}
        onCancel={() => setState(prevState => ({ ...prevState, modal: false }))}
        footer={null} 
        width={1300}
        >
          
    <div>
      <ApiSubmission id={state.id} />
    </div>
  </Modal>
      {state.loading ? <Loader /> : (
        <>
        <Table
        columns={columns}
        dataSource={state.ApiData}
      />        </>
      )}
    </div>
  );
};

export default Api_management;
