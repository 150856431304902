import React, {useRef, useEffect, useState} from "react";
import Loader                               from '../../ResuseableComponnets/Loader';
import openNotificationWithIcon             from '../../ResuseableComponnets/Toaster';
import ChatBotHeader from "../../Settings/Teams/chatBotHeader";
import InstagramBackgroundImage from "../../../assets/backgroundImages/whastappback.png";
import {Button, Spin} from "antd";
import { LoadingOutlined} from '@ant-design/icons';
import {useDispatch, useSelector}                                                from "react-redux";
import { markAsRead } from '../Apis';
import Messages                                                     from '../Messages/Messages';
import {getWhatsappConversationAction}             from "../states/Actions";

const ChatPanel = (props) => {
    const [state, setState] = useState({
        items: Array.from({length: 20}),
        hasMore: true,
        pageNum: 0,
        allMessages:[],
        isLoading:false,
        messagesCount:0
    })
    const messagesRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    let dispatch = useDispatch()
    let conversataions= useSelector(state => state?.inbox?.allWhatsappConversations)
    let getCustomer = conversataions.length > 0 && conversataions.find(item => props?.allChatInfo?.conversationId === item?.conversationId)
    let messagesArray = !!getCustomer && getCustomer?.messages
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const scrollToBottom = () => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    };

    const fetchMoreData = async () => {
        setState({...state, isLoading: true})
        try {
            const nextPageNum = state.pageNum + 1;
            const response = await props.getChat(props?.allChatInfo?.conversationId, nextPageNum);
            const newMessages = response.data.data.messages;
            dispatch(getWhatsappConversationAction({
                messages:newMessages,
                conversationId:props?.allChatInfo?.conversationId
            }))
            state.pageNum===1 && newMessages.length > 0 && scrollToBottom()
            setState(prevState => ({
                ...prevState,
                isLoading: false,
                pageNum: nextPageNum,
                messagesCount:response.data.data.count
            }))
        } catch (error) {
            setState({
                ...state,
                isLoading: false,
                pageNum: state.pageNum + 1
            })
            openNotificationWithIcon('error',
              'Error Message',
              error?.response?.data?.message
            )
            console.error('Error fetching more messages:', error);
        }
    };

    useEffect(() => {
        props?.allChatInfo?.conversationId && fetchMoreData()
    }, []);

    useEffect(() => {
        state.pageNum===1 && scrollToBottom()
    }, [state.pageNum===1]);

     useEffect(()=>{
        scrollToBottom()
         let lastMessage = messagesArray[messagesArray.length-1]
         let postData = {
             conversationId:lastMessage?.conversationId,
             messageId:lastMessage?._id
         }
         !!lastMessage && !!lastMessage.isIncoming && markAsRead(postData).then(()=>console.log()).catch(()=>console.log())
     }, [!!messagesArray && messagesArray.length > 0 && messagesArray.length])

    return <div style={{height: "calc(100vh - 15rem)"}}>
        <ChatBotHeader
            loaderInChatPanel={props.loaderInChatPanel}
            channel={props.channel}
            assignAgentFunc={props.assignAgentFunc}
            assignStatusFunc={props.assignStatusFunc}
            handleSearchChange={handleSearchChange}
            searchQuery={searchQuery}
            openRightSectionOpen={props.openRightSectionOpen}
            openRangePicker={props.openRangePicker}
            getAllChat={props.getAllChat}
            showMessageBar={props.showMessageBar}
            isBotInbox={props.isBotInbox}
            assignedAgentToInteraction={props.assignedAgentToInteraction}
            showBottomSection={props.showBottomSection}
            allChatInfo={props.allChatInfo}
            tabId={props.tabId}
            showChat={props.showChat}
            getChatInformation={props.getChatInformation}
            allMessagesLength={state?.allMessages?.length}
            changeInboxTab={props.changeInboxTab}
        />
        <div
            id={'scrollableDiv'}
            className='messages-container'
            ref={messagesRef}
            style={{
                overflowY:'auto',
                backgroundImage: props.channel === "whatsapp" && `url(${InstagramBackgroundImage})`
            }}
        >
            {
                state.isLoading ? <Loader message={'Getting all messages'} height={'100%'}/> :
              !!messagesArray && messagesArray.length > 0 &&  <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>
                {
                    state.isLoading ?
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                    spin
                                />
                            }
                        /> :
                        (!!messagesArray && messagesArray.length < state.messagesCount) && <Button onClick={() => fetchMoreData()}
                                type="primary">Load more</Button>
                }
            </div>
            }
            <Messages
              aiGenerateMsgFunc={props.aiGenerateMsgFunc}
              messagesArray={ !!messagesArray && messagesArray}
              channel={props.channel}
            />
        </div>
    </div>
}
export default ChatPanel