import { Form, Select } from 'antd';
import React, { useState ,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, Plus } from 'lucide-react';
import { FaRobot, FaMessage, FaTags } from "react-icons/fa6";
import './Segment.scss';
import WhatsAPPLgo from "../../assets/svg/social-logos/whatsappLogo.png"
import Header from "./Reuseable/Header"
import { useNavigate } from "react-router-dom";
import {getDropdown} from "./APIS/Apis"



const { Option } = Select;

const Createsegment = (props) => {
    const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
    const [state, setState] = useState({
    allTags: [],
    allGroups: [],
    allApis: [],
    collaps: {
      chatbotMenu: true,
      customerFeedback: true,
      conversionTags: true,
      shopify: true,
    }
  });

  const toggleCollapse = (key) => {
    setState((prev) => ({
      ...prev,
      collaps: {
        ...prev.collaps,
        [key]: !prev.collaps[key],  
      }
    }));
  };
  const options = [
    { value: 1, label: "Ask Address Information" },
    { value: 2, label: "Ask House Information" },
    { value: 3, label: "Ask Name" },
    { value: 4, label: "Ask Pickup or Delivery" },
    { value: 5, label: "Order Confirmed" },
    { value: 6, label: "Welcome Message With Catalog" },
  ];


  const DataDrop = () =>{
    getDropdown()
    .then((res)=>{
      console.log(res,834766535)
    })
  }

  useEffect(()=>{
    // DataDrop()
  },[])

  return (
    <div className="synctic-module-container">
      <div className="segment-container">
        <Header
          name="Segment Manger"
          summary="Back to summary"
          Back="Back"
          Continue="Submit"
          BackonClick={()=>navigate("/Segments/SegmentForm")}
          />
         <div className="segment-header" style={{marginTop:"1%"}}>
          <div className="header-content">
            <div className="channel-info">
            <img src={WhatsAPPLgo} alt="WhatsAPPLgo" height={40} width={40} />
            <div>
                <div className="info-label">Segment Channel</div>
                <div className="info-value">WhatsApp</div>
              </div>
            </div>

            <div>
              <div className="info-label">Segment Name</div>
              <div className="info-value">Entrie</div>
            </div>

            <div>
              <div className="info-label">Segment Type</div>
              <div className="info-value">Entire Audience</div>
            </div>
          </div>
        </div>

        <div className="segment-rules">
          <h2 className="rules-title">Segment Rules</h2>
          <p className="rules-description">Define rules for your audience segment.</p>

          {/* Chatbot Menu */}
          <div className="rule-card">
            <div className="rule-card-inner">
              <div className="rule-header" onClick={() => toggleCollapse('chatbotMenu')}>
                <div className="rule-title">
                  <span className="rule-icon"><FaRobot /></span>
                  <span className="rule-label">Chatbot Menu</span>
                </div>
                <div className="rule-limit">
                  <span>You can add up to 5 rules</span>
                  <ChevronDown className={`chevron-icon ${state.collaps.chatbotMenu ? 'rotated' : ''}`} />
                </div>
              </div>
              <div className={`rule-content ${state.collaps.chatbotMenu ? 'collapsed' : ''}`}>
                <Select className="rule-select" defaultValue="Ask Pickup or Delivery">
                  {options.map(option => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                  ))}
                </Select>
                <div className="equals-text">Equals To</div>
                <Select className="rule-select" defaultValue="true">
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
                <button className="add-rule-button">
                  <Plus className="add-rule-icon" />
                </button>
              </div>
            </div>
          </div>

          {/* Customer Feedback */}
          <div className="rule-card">
            <div className="rule-card-inner">
              <div className="rule-header" onClick={() => toggleCollapse('customerFeedback')}>
                <div className="rule-title">
                  <span className="rule-icon"><FaMessage /></span>
                  <span className="rule-label">Customer Feedback</span>
                </div>
                <div className="rule-limit">
                  <span>You can add up to 5 rules</span>
                  <ChevronDown className={`chevron-icon ${state.collaps.customerFeedback ? 'rotated' : ''}`} />
                </div>
              </div>
              <div className={`rule-content ${state.collaps.customerFeedback ? 'collapsed' : ''}`}>
                <Select className="rule-select" defaultValue="Average">
                  {options.map(option => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                  ))}
                </Select>
                <div className="equals-text">Equals To</div>
                <Select className="rule-select" defaultValue="true">
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
                <button className="add-rule-button">
                  <Plus className="add-rule-icon" />
                </button>
              </div>
            </div>
          </div>

          {/* Conversion Tags */}
          <div className="rule-card">
            <div className="rule-card-inner">
              <div className="rule-header" onClick={() => toggleCollapse('conversionTags')}>
                <div className="rule-title">
                  <span className="rule-icon"><FaTags /></span>
                  <span className="rule-label">Conversion Tags</span>
                </div>
                <div className="rule-limit">
                  <span>You can add up to 5 rules</span>
                  <ChevronDown className={`chevron-icon ${state.collaps.conversionTags ? 'rotated' : ''}`} />
                </div>
              </div>
              <div className={`rule-content ${state.collaps.conversionTags ? 'collapsed' : ''}`}>
                <Select className="rule-select" defaultValue="Ask Pickup or Delivery">
                  {options.map(option => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                  ))}
                </Select>
                <div className="equals-text">Equals To</div>
                <Select className="rule-select" defaultValue="true">
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
                <button className="add-rule-button">
                  <Plus className="add-rule-icon" />
                </button>
              </div>
            </div>
          </div>

          {/* Shopify */}
          
        </div>
      </div>
    </div>
  );
};

export default Createsegment;
