import React from 'react';
import axios from "../../Authentication/headers";
export function AddContact(data) {
    return axios.post("/contact/create", data)
}

export function GetAllContacts(query) {
    return axios.get(`/contact/all?${query}`)
}

export function GetSpecificContact(id) {
    return axios.get(`/contact/${id}`)
}

export function updateContactApi(id, data) {
    return axios.put(`/contact/update/${id}`, data)
}
export function blockContactApi(id) {
    return axios.get(`/contact/block/${id}`)
}

export function UnBlockContactApi(id) {
    return axios.get(`/contact/unblock/${id}`)
}

export function deleteContactApi(id) {
    return axios.get(`/contact/delete/${id}`)
}

export function exportContacts(data) {
    return axios.post(`/contact/export`, data)
}

export function filterContacts(query, data) {
    return axios.post(`/contact/filter?${query}`, data)
}

