import axios from "../../Authentication/headers";

export function addGroup(data) {
    return axios.post("/contact/group/create", data)
}

export function GetAllGroups() {
    return axios.get(`/contact/group/all`)
}


export function getGroupById(id) {
    return axios.get(`/contact/group/${id}`)
}

export function updateGroup(id, data) {
    return axios.put(`/contact/group/update/${id}`, data)
}

export function activateGroup(id) {
    return axios.get(`/contact/group/activate/${id}`)
}

export function deActivateGroup(id) {
    return axios.get(`/contact/group/deactivate/${id}`)
}

export function deleteGroup(id) {
    return axios.get(`/contact/group/delete/${id}`)
}

export function addMember(data) {
    return axios.post("/contact/group/addMember", data)
}

export function removeMember(data) {
    return axios.post("/contact/group/removeMember", data)
}