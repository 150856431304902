import { DeleteFilled }                               from '@ant-design/icons';
import { Select }                                     from 'antd';
import { ChevronDown, Plus }                          from 'lucide-react';
import React, { useState, useEffect }                 from 'react';
import { FaRobot, FaMessage, FaTags }                 from "react-icons/fa6";
import './Segment.scss';
import { useNavigate, useParams }                     from "react-router-dom";
import WhatsAPPLgo                                    from "../../assets/svg/social-logos/whatsappLogo.png"
import axios                                          from '../Authentication/headers';
import Loader                                         from '../ResuseableComponnets/Loader';
import openNotificationWithIcon                       from '../ResuseableComponnets/Toaster';
import { getChatBotMenu, getAllTags, getSegmentById } from "./APIS/Apis"
import Header                                         from "./Reuseable/Header"

const { Option } = Select;

const Entireaudience = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    allBotsData: [
      {
        botId: "",
        isInteract: false,
      },
    ],
    dataResponse: null,
    loader: true,
    customerFeedbackData: [
      {
        feedback: "",
        isInteract: false,
      },
    ],
    allTagsData: [
      {
        tagId: "",
        isInteract: false,
      },
    ],
    allTags: [],
    cusstomerFeedback: [
      { label: "Poor", value: "poor" },
      { label: "Average", value: "average" },
      { label: "Great", value: "great" },
    ],
    allBots: [],
    collaps: {
      chatbotMenu: true,
      customerFeedback: true,
      conversionTags: true,
      shopify: true,
    },
  });
  const { id, name, type } = useParams()
  const toggleCollapse = (key) => {
    setState((prev) => ({
      ...prev,
      collaps: {
        ...prev.collaps,
        [key]: !prev.collaps[key],
      }
    }));
  };

  const getChatBotMenuFunc = async () => {
    console.log(8765434567811)
    try {
      const [response, tagsResponse, responseSegmentData] = await Promise.all([
        getChatBotMenu(),
        getAllTags(),
        !!id && getSegmentById(id)
      ]);

      const segmentData = responseSegmentData?.data?.data || {};

      console.log(response, tagsResponse, 876543111)

      setState((prevState) => ({
        ...prevState,
        allBotsData: id && segmentData.chatBotList ? segmentData.chatBotList : [],
        customerFeedbackData: id && segmentData.customerFeedback ? segmentData.customerFeedback : [],
        allTagsData: id && segmentData.tagList ? segmentData.tagList : [],
        dataResponse: id && segmentData,
        allBots: response?.data?.data || [],
        allTags: tagsResponse?.data?.data || [],
        loader: false
      }));
    }
    catch (error) {
      console.error("Error fetching chatbot menu data:", error);
      setState((prevState) => ({
        ...prevState,
        allBotsData: [],
        customerFeedbackData: [],
        allTagsData: [],
        allBots: [],
        allTags: [],
        loader: false,
        dataResponse: null
      }));
    }
  };


  const addValue = (module, key, value, field) => {
    const dataKey =
      module === "allBots"
        ? "allBotsData"
        : module === "customerFeedback"
          ? "customerFeedbackData"
          : "allTagsData";

    const updatedArray = state[dataKey].length > 0 && state[dataKey].map((item, index) => {
      if (index === key) {
        if (module === "allBots") {
          return {
            ...item,
            ...(field === "one" ? { botId: value } : { isInteract: value }),
          };
        }
        else if (module === "customerFeedback") {
          return {
            ...item,
            ...(field === "one" ? { feedback: value } : { isInteract: value }),
          };
        }
        else if (module === "conversionTags") {
          return {
            ...item,
            ...(field === "one" ? { tagId: value } : { isInteract: value }),
          };
        }
      }
      return item;
    });

    setState({
      ...state,
      [dataKey]: updatedArray,
    });
  };


  const addNewRule = async (module) => {
    const dataKey =
      module === "allBots"
        ? "allBotsData"
        : module === "customerFeedback"
          ? "customerFeedbackData"
          : "allTagsData";

    const newItem =
      module === "allBots"
        ? { botId: "", isInteract: false }
        : module === "customerFeedback"
          ? { feedback: "", isInteract: false }
          : { tagId: "", isInteract: false };

    const limit = module === "customerFeedback" ? 3 : 5;

    if (state[dataKey].length < limit) {
      setState({
        ...state,
        [dataKey]: [...state[dataKey], newItem],
      });
    }
    else {
      alert(`You can only add up to ${ limit } rules.`);
    }
  };

  const deleteRule = async (module, key) => {
    const dataKey =
      module === "allBots"
        ? "allBotsData"
        : module === "customerFeedback"
          ? "customerFeedbackData"
          : "allTagsData";

    const updatedArray = [...state[dataKey]];
    await updatedArray.splice(key, 1);

    setState({
      ...state,
      [dataKey]: updatedArray,
    });
  };


  const createFunc = async () => {
    let formData = new FormData()
    let data = {
      name: name,
      category: type,
      chatBotList: state.allBotsData,
      customerFeedback: state.customerFeedbackData,
      tagList: state.allTagsData
    }

    data = id ? {
      ...data,
      category: state.dataResponse.category,
      name: state.dataResponse.name
    } : data

    formData.append('data', JSON.stringify(data));

    !id && axios.post("https://backend.tiebot.io/segment/create", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${ localStorage.getItem('token') }`,
      },
    }).then(response => {
      navigate("/segments")
      openNotificationWithIcon('success',
        'Success Message',
        'Contact created successfully'
      )
    }).catch(err => {
      openNotificationWithIcon('error',
        'Error Message',
        err?.response?.data?.message
      )
    })
    !!id && axios.put(`https://backend.tiebot.io/segment/update/${ id }`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${ localStorage.getItem('token') }`,
      },
    }).then(response => {
      navigate("/segments")
      openNotificationWithIcon('success',
        'Success Message',
      )
    }).catch(err => {
      openNotificationWithIcon('error',
        'Error Message',
        err?.response?.data?.message
      )
    })
  }

  useEffect(() => {
    getChatBotMenuFunc()
  }, [])

  return (
    state.loader ? <Loader/> :
      <div className="synctic-module-container">
        <div className="segment-container">
          <Header
            name="Entire Audience"
            summary="Back to summary"
            Back="Back"
            Continue={ !id ? "Submit" : "Update" }
            clickFunction={ () => createFunc() }
          />
          <div className="segment-header" style={ { marginTop: "1%" } }>
            <div className="header-content">
              <div className="channel-info">
                <img src={ WhatsAPPLgo } alt="WhatsAPPLgo" height={ 40 } width={ 40 }/>
                <div>
                  <div className="info-label">Segment Channel</div>
                  <div className="info-value">WhatsApp</div>
                </div>
              </div>

              <div>
                <div className="info-label">Segment Name</div>
                <div className="info-value">{
                  !!id && state?.dataResponse?.category === "entire_audience" ?
                    "Entire Audience" :
                    !!id && state?.dataResponse?.category === "active_last_24_hours" ?
                      "Last 24 hours" :
                      type === "entire_audience" ?
                        "Entire audience" :
                        "Last 24 hours"
                }</div>
              </div>

              <div>
                <div className="info-label">Segment Type</div>
                <div className="info-value">{
                  !!id && state?.dataResponse?.category === "entire_audience" ?
                    "Entire Audience" :
                    !!id && state?.dataResponse?.category === "active_last_24_hours" ?
                      "Last 24 hours" :
                      type === "entire_audience" ?
                        "Entire audience" :
                        "Last 24 hours"
                }</div>
              </div>
            </div>
          </div>

          <div className="segment-rules">
            <h2 className="rules-title">Segment Rules</h2>
            <p className="rules-description">Define rules for your audience segment.</p>

            <div className="rule-card">
              <div className="rule-card-inner">
                <div className="rule-header" onClick={ () => toggleCollapse('chatbotMenu') }>
                <div className="rule-title">
                    <span className="rule-icon"><FaRobot/></span>
                    <span className="rule-label">Chatbot Menu</span>
                  </div>
                  <div className="rule-limit">
                    <span>You can add up to 5 rules</span>
                    <ChevronDown className={ `chevron-icon ${ state?.collaps?.chatbotMenu ? 'rotated' : '' }` }/>
                  </div>
                </div>
                { state.allBotsData.map((item, key) => (
                  <div style={ {
                    marginTop: 10
                  } }
                       key={ `allBots-${ key }` }
                       className={ `rule-content ${
                         state?.collaps?.chatbotMenu ? "collapsed" : ""
                       }` }
                  >
                    <Select
                      onSelect={ (e) => addValue("allBots", key, e, "one") }
                      className="rule-select"
                      defaultValue={ item.botId }
                      placeholder={ "Select" }
                    >
                      { state.allBots.map((option) => (
                        <Option key={ option.value } value={ option.value }>
                          { option.label }
                        </Option>
                      )) }
                    </Select>
                    <div className="equals-text">Equals To</div>
                    <Select
                      onSelect={ (e) => addValue("allBots", key, e, "two") }
                      className="rule-select"
                      defaultValue={ item.isInteract }
                    >
                      <Option value={ true }>True</Option>
                      <Option value={ false }>False</Option>
                    </Select>
                    <DeleteFilled onClick={ () => deleteRule("allBots", key) }/>
                  </div>
                )) }
                { state.allBotsData.length < 5 && (
                  <button disabled={ state.allBotsData.find(item => item.botId === "") } className="add-rule-button"
                          onClick={ () => addNewRule("allBots") }>
                    <Plus className="add-rule-icon"/>
                    Add Rule
                  </button>
                ) }
              </div>
            </div>

            <div className="rule-card">
              <div className="rule-card-inner">
                <div className="rule-header" onClick={ () => toggleCollapse('customerFeedback') }>
                  <div className="rule-title">
                    <span className="rule-icon"><FaMessage/></span>
                    <span className="rule-label">Customer Feedback</span>
                  </div>
                  <div className="rule-limit">
                    <span>You can add up to 5 rules</span>

                    <ChevronDown className={ `chevron-icon ${ state?.collaps?.customerFeedback ? 'rotated' : '' }` }/>
                  </div>
                </div>
                { state.customerFeedbackData.map((item, key) => (
                  <div style={ {
                    marginTop: 10
                  } }
                       key={ `customerFeedback-${ key }` }
                       className={ `rule-content ${
                         state?.collaps?.customerFeedback ? "collapsed" : ""
                       }` }
                  >
                    <Select
                      onSelect={ (e) => addValue("customerFeedback", key, e, "one") }
                      className="rule-select"
                      defaultValue={ item.feedback || "" }
                      placeholder={ "Select" }
                    >
                      { state.cusstomerFeedback.length > 0 && state.cusstomerFeedback.map((option) => (
                        <Option key={ option.value } value={ option.value }>
                          { option.label }
                        </Option>
                      )) }
                    </Select>
                    <div className="equals-text">Equals To</div>
                    <Select
                      onSelect={ (e) => addValue("customerFeedback", key, e, "two") }
                      className="rule-select"
                      defaultValue={ item?.isInteract }
                    >
                      <Option value={ true }>True</Option>
                      <Option value={ false }>False</Option>
                    </Select>
                    <DeleteFilled onClick={ () => deleteRule("customerFeedback", key) }/>
                  </div>
                )) }
                { state.customerFeedbackData.length < 3 && (
                  <button
                    disabled={ state.customerFeedbackData.find(item => item.feedback === "") }
                    className="add-rule-button"
                    onClick={ () => addNewRule("customerFeedback") }
                  >
                    <Plus className="add-rule-icon"/>
                    Add Rule
                  </button>
                ) }
              </div>
            </div>

            <div className="rule-card">
              <div className="rule-card-inner">
                <div className="rule-header" onClick={ () => toggleCollapse('conversionTags') }>
                  <div className="rule-title">
                    <span className="rule-icon"><FaTags/></span>
                    <span className="rule-label">Conversion Tags</span>
                  </div>
                  <div className="rule-limit">
                    <span>You can add up to 5 rules</span>
                    <ChevronDown className={ `chevron-icon ${ state.collaps.conversionTags ? 'rotated' : '' }` }/>
                  </div>
                </div>
                { state.allTagsData.map((item, key) => (
                  <div style={ {
                    marginTop: 10
                  } }
                       key={ `conversionTags-${ key }` }
                       className={ `rule-content ${
                         state.collaps.conversionTags ? "collapsed" : ""
                       }` }
                  >
                    <Select
                      onSelect={ (e) => addValue("conversionTags", key, e, "one") }
                      className="rule-select"
                      defaultValue={ item.tagId || "" }
                      placeholder={ "Select" }
                    >
                      { state.allTags.map((option) => (
                        <Option key={ option._id } value={ option._id }>
                          { option.label }
                        </Option>
                      )) }
                    </Select>
                    <div className="equals-text">Equals To</div>
                    <Select
                      onSelect={ (e) => addValue("conversionTags", key, e, "two") }
                      className="rule-select"
                      defaultValue={ item.isInteract }
                    >
                      <Option value={ true }>True</Option>
                      <Option value={ false }>False</Option>
                    </Select>

                    <DeleteFilled onClick={ () => deleteRule("conversionTags", key) }/>
                  </div>
                )) }
                { state.allTagsData.length < 5 && (
                  <button
                    disabled={ state.allTagsData.find(item => item.tagId === "") }
                    className="add-rule-button"
                    onClick={ () => addNewRule("conversionTags") }
                  >
                    <Plus className="add-rule-icon"/>
                    Add Rule
                  </button>
                ) }
              </div>
            </div>

          </div>
        </div>
      </div>
  );
};

export default Entireaudience;
