import { Button, Form, Input, Select, Drawer } from 'antd';
import React, { useEffect, useState }          from 'react';
import openNotificationWithIcon                from "../../../ResuseableComponnets/Toaster";
import { getAllActivatedTags }                 from "../../../Settings/Tags/API/Api";
import { AddContact, updateContactApi }        from "../../Api/Api";
import { GetAllGroups, addMember }             from '../../Api/groupApis';
import { useTranslation }                      from 'react-i18next';

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const ContactForm = (props) => {
  const {t} = useTranslation()
  const [state, setState] = useState({
    allTags: [],
    allGroups: []
  })
  const [form] = Form.useForm();
  const onFinish = (values) => {
    props.mode === 'new' ?
      onSubmit(values) : onUpdate(values)
  };
  const onSubmit = (data) => {
    AddContact(data).then(() => {
      props.getContacts()
      form.resetFields()
    }).catch((err) => {
      openNotificationWithIcon('error',
        'Error Message',
        err?.response?.data?.message
      )
    })
  }
  const onUpdate = (data) => {
    updateContactApi(props.contactId, data).then(() => {
      props.getContacts("pagination=1")
      form.resetFields()
    }).catch(error => console.log(error))
  }
  const getAllTagsAndGroups = async () => {
    let allActiveTags = await getAllActivatedTags()
    let allGroups = await GetAllGroups()
    setState({
      ...state,
      allGroups: !!allGroups?.data?.data?.rows ? allGroups?.data?.data?.rows : [],
      allTags: !!allActiveTags?.data?.data ? allActiveTags.data.data : []
    })
  }

  useEffect(() => {
    getAllTagsAndGroups()
  }, []);
  return (
    <Drawer placement={ 'right' }
            title={ props.mode === 'new' ? 'Create contact' : 'Update contact' }
            open={ props.openDrawer }
            width={ 500 }
            onClose={ () => {
              props.closeDialogue()
              form.resetFields()
            } }
    >
      <Form
        { ...formItemLayout }
        form={ form }
        name="register"
        onFinish={ onFinish }
        scrollToFirstError
        initialValues={ props.mode !== 'new' && {
          ...props.stateData
        } }
        layout="vertical"
        style={{width:'100%'}}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
      >
          <Form.Item
          name="name"
          label={t("Name")}
          rules={ [
            {
              type: 'string'
            },
            {
              required: true,
              message: 'Please enter your name',
            },
          ] }
        >
          <Input placeholder="Name"/>
        </Form.Item>

        <Form.Item
          name="phone"
          label={t("Phone Number")}
          rules={ [
            {
              required: true,
              message: 'Please input your phone number!'
            },
          ] }
        >
          <Input placeholder="Phone" style={ { width: '100%' } }/>
        </Form.Item>

        <Form.Item
          name="email"
          label={t("E-mail")}
          rules={ [
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            }
          ] }
        >
          <Input placeholder="Email"/>
        </Form.Item>

        <Form.Item
          name="opted"
          label={t("Opted In")}
        >
          <Select placeholder="Opted in">
            <Option value="in">In</Option>
            <Option value="out">Out</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="address"
          label={t("Address")}
        >
          <Input placeholder="Address"/>
        </Form.Item>

        <Form.Item
          name="experience"
          label={t("Experience")}
        >
          <Input placeholder="Experience"/>
        </Form.Item>

        <Form.Item
          name="age"
          label={t("Age")}
        >
          <Input placeholder="Age"/>
        </Form.Item>
        <Form.Item
          name="interest"
          label={t("Interest")}
        >
          <Input placeholder="Interest"/>
        </Form.Item>
        <Form.Item
          name="city"
          label={t("City")}
        >
          <Input placeholder="City"/>
        </Form.Item>
        <Form.Item
          name="state"
          label={t("State")}
        >
          <Input placeholder="State"/>
        </Form.Item>

        <Form.Item
          name="gender"
          label={t("Gender")}
        >
          <Select placeholder="select your gender">
            <Option value="male">{t("Male")}</Option>
            <Option value="female">{t("Female")}</Option>
            <Option value="other">{t("Other")}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="source"
          label="Source"
        >
          <Select placeholder="select source">
            <Option value="whatsapp">Whatsapp</Option>
            <Option value="facebook">Facebook</Option>
            <Option value="instagram">Instagram</Option>
          </Select>
        </Form.Item>


        <Form.Item
          name="tags"
          label={t("Tags")}
          rules={ [
            {
              type: 'array',
            }
          ] }
        >
          <Select mode="multiple" placeholder="Please select tag">
            {
              state?.allTags.length > 0 && state.allTags.map(obj => <Option value={ obj.name }>{ obj.name }</Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item
          name="groups"
          label={t("Select group")}
          rules={ [
            {
              type: 'array'
            }
          ] }
        >
          <Select mode="multiple" placeholder="Select Group">
            { !!state.allGroups.length > 0 && state.allGroups.map(item => <Option
              value={ item?._id }>{ item?.name }</Option>) }
          </Select>
        </Form.Item>

        <Form.Item { ...tailFormItemLayout }>
          <Button type="primary" htmlType="submit">
            { props.mode === 'new' ? t('Submit') : t('Update') }
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default ContactForm;