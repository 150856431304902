import axios from "../../Authentication/headers";
export function getAllTemplates(query) {
    return axios.get(`/template/all?${query}`)
}
export function getApprovedTemplates() {
    return axios.get("/template/approved")
}

export function getRejectedTemplates() {
    return axios.get("/template/reject")
}

export function getPendingTemplates() {
    return axios.get("/template/pending")
}

export function getTemplateById(id) {
    return axios.get(`/template/${id}`)
}

export function createTemplates(data) {
    return axios.post("/template/create", data)
}

export function updateTemplates(id, data) {
    return axios.put(`/template/update/${id}`, data)
}

export function createAuthTemplate(data) {
    return axios.post("/template/createAuth", data)
}

export function updateAuthTemplate(id, data) {
    return axios.put(`/template/updateAuth/${id}`, data)
}