import React from 'react';
import "./Integration.scss";
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import { AiFillApi } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import { SiShopify } from "react-icons/si";
import { GiMedusaHead } from "react-icons/gi";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const Integration = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const AllData = [
        {
            icon1: <SiShopify style={{ color: "#319531" }} />,
            icon2: <AiOutlinePlus style={{ cursor: "pointer" }} />,
            heading: "Shopify",
            Descriptions: "Integrate your Shopify store into Syntic for order management and matching customers with conversations.",
            navigate: "/settings/integrations/shopify",
        },
        {
            icon1: <GiMedusaHead style={{ color: "blue" }} />,
            icon2: <AiOutlinePlus style={{ cursor: "pointer" }} />,
            heading: "OpenAI",
            Descriptions: "Integrate your Medusa store into Syntic for order management and matching customers with conversations.",
            navigate: "/settings/integrations/openApi",
        },
    ];
    

    return (
        <div className="synctic-module-container">
            <SettingsHeader
                icon={<AiFillApi className='icon' />}
                heading={t("Integrations")}
                description={t("Integrate-discription")}
            />
            <div style={{display:"flex",gap:"10px",marginTop: '3%'}}>
            {AllData.map((item, index) => (
                <div className='cards-box' style={{width:"30%",boxShadow:"5px 5px 10px #0003",borderRadius:"15px"}} key={index}>
                    <div className='icons-container'>
                        <div className='prop-icon'>{item.icon1}</div>
                        <div 
                            className='navigate-link' 
                            onClick={() => navigate(item.navigate)}
                        >
                            <div className='props-icon2'>{item.icon2}</div>
                        </div>
                    </div>
                    <div className='container-Border'>
                        <div className='Heading'>{t(item.heading)}</div>
                        <p className='Heading-dis'>{t(item.Descriptions)}</p>
                    </div>
                </div>
            ))}
            </div>
        </div>
    );
};

export default Integration;
