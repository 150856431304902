import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Switch, Tooltip, Tabs, Avatar } from "antd";
import { CheckOutlined, CloseOutlined, UserOutlined } from "@ant-design/icons";
import { IoIosNotifications } from "react-icons/io";
import { FaExternalLinkAlt } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { PiSpeakerSimpleHighFill } from "react-icons/pi";
import { GoDotFill } from "react-icons/go";
import moment from "moment";
import "./Header.scss";
import { notificationlist, markallNotificationRead, notificationunread } from "./Api/Api";
import { useNavigate } from "react-router-dom";
import openNotificationWithIcon from "./Toaster";


const Notification = () => {
  const [state, setState] = useState({
    checked: false,
    Latest: [],
    newData: [],
    title: "",
    message: "",
    type: "",
    to: ""
  });
  const navigate = useNavigate();

  const UnreadFunc = () => {
    notificationunread()
      .then((res) => {
        if (res.data && res.data.message) {
          openNotificationWithIcon(
            "success",
            "Success Message",
            res.data.message
          );
        } 
        const newdata = res?.data?.data?.rows || [];
        const alldata = newdata.map((item, index) => ({
          key: index,
          id: item._id,
          createdAt: item.createdAt,
          message: item.message,
          title: item.title,
          readAt: item.readAt || "",
        }));
        
        setState((prevState) => ({
          ...prevState,
          Latest: alldata,
          newData: alldata.map((item) => item.readAt),
        }));
        
        console.log("Processed Data:", alldata, 88766);
      })
      .catch((error) => {
        console.error("Error creating team:", error);
        openNotificationWithIcon(
          "error",
          "Error Message",
          error.response?.data?.message || "Please try again later."
        );
      });
  };

  const Notifylist = () => {
    notificationlist()
      .then((res) => {
        const newdata = res?.data?.data?.rows || [];
        const alldata = newdata.map((item, index) => ({
          key: index,
          id: item._id,
          createdAt: item.createdAt,
          message: item.message,
          title: item.title,
          readAt: item.to?.readAt || ""
        }));
        setState(prevState => ({
          ...prevState,
          Latest: alldata,
          newData: alldata.map(item => item.readAt)
        }));

        console.log(alldata, 8737636);
      })
      .catch((error) => {
        console.error("Error fetching notification list:", error);
      });
  };

  useEffect(() => {
    Notifylist();
  }, []);

  const markAllRead = () => {
    const data = {
      title: state.title,
      message: state.message,
      type: state.type,
      to: state.to
    };
    
    markallNotificationRead(data)
           .then((res) => {
      if (res.data && res.data.message) {
               openNotificationWithIcon(
                 "success",
                 "Success Message",
                 res.data.message
               );
             }      })
           .catch((error) => {
                   console.error("Error creating team:", error);
                   openNotificationWithIcon(
                     "error",
                     "Error Message",
                     error.response?.data?.message || "Please try again later."
                   );
                 });
};


  const { TabPane } = Tabs;
  const text = <span>View All Notifications</span>;

  const renderNotificationItem = (notification, index) => (
    <div key={index} style={{ display: "flex", gap: "4%" }}>
      <Avatar
        size="large"
        icon={<UserOutlined />}
        style={{ marginTop: "2%" }}
      />
      <div
        className="notification-heading"
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "2%",
          fontFamily: "inherit",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <div style={{ fontSize: "15px", fontWeight: "500" }}>
            {notification.title}
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginTop: "5px",
            }}
          >
            <span
              style={{
                fontSize: "12px",
                opacity: ".7",
              }}
            >
              {moment(notification.createdAt)
                .startOf("hour")
                .fromNow()}
            </span>
            <span
              style={{
                fontSize: "12px",
                opacity: ".7",
                marginLeft: "7px",
              }}
            >
              {state.newData === null ? (
                <GoDotFill
                  style={{
                    color: "green",
                    fontSize: "13px",
                    display: "block",
                  }}
                />
              ) : (
                <span style={{ display: "none" }}>
                  {moment(notification.createdAt).format("MM-DD-YYYY")}
                </span>
              )}
            </span>
          </div>
        </div>
        <div>{notification.message}</div>
        <span style={{ fontSize: "12px", opacity: ".7" }}>
          {moment(notification.createdAt).format("MM-DD-YYYY")}
        </span>
      </div>
    </div>
  );

  const menu = (
    <Menu
      style={{
        width: "70vh",
        background: "white",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
      }}
    >
      <div key="notification">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
            padding: "15px",
          }}
        >
          <h2 style={{ fontSize: "20px", fontFamily: "inherit" }}>Activity</h2>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <div style={{ display: "flex", gap: "5px" }}>
              <div>Only Show Unread</div>
              <Switch
                size="small"
                style={{ margin: "4px" }}
                checked={state.checked}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setState(prevState => ({
                    ...prevState,
                    checked,
                  }));
                  
                  if (checked) {
                    UnreadFunc();
                  } else {
                    Notifylist();
                  }
                }}
              />
            </div>

            <Tooltip placement="bottom" title={text}>
              <FaExternalLinkAlt 
                style={{ fontSize: "20px" }}
                onClick={() => navigate("/ResuseableComponnets/NotifyTable")}
              />
            </Tooltip>

            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <div>
                      <PiSpeakerSimpleHighFill
                        style={{
                          fontSize: "20px",
                          transform: "rotate(330deg)",
                          marginRight: "4px",
                        }}
                      />
                      Give your feedback
                    </div>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <a href="#">
                <BsThreeDotsVertical
                  style={{ fontSize: "21px", fontWeight: "bold" }}
                />
              </a>
            </Dropdown>
          </div>
        </div>
      </div>

      <div
        style={{
          height: "65vh",
          overflowY: "scroll",
        }}
      >
        <Tabs defaultActiveKey="1" style={{ width: "93%", margin: "auto" }}>
          <TabPane tab="Notifications" key="1">
            {state.checked ? (
              <div>
                {state.Latest.map(renderNotificationItem)}
              </div>
            ) : (
              <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="status-notify">Latest</div>
                  <div className="Mark-heading" onClick={markAllRead}>
                    Mark all as read
                  </div>
                </div>
                <div>
                  {state.Latest.map(renderNotificationItem)}
                </div>
              </div>
            )}
          </TabPane>
        </Tabs>
      </div>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <IoIosNotifications
        color={"grey"}
        style={{
          fontSize: "22px",
          cursor: "pointer",
          transform: "rotate(4001deg)",
        }}
      />
    </Dropdown>
  );
};

export default Notification;