import React, {useEffect, useState} from "react";
import "./App.scss";
import { useSelector }              from 'react-redux';
import Login from "./Modules/Authentication/Login/Login";
import Signup from "./Modules/Authentication/Signup/Signup";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../src/assets/css/style.bundle.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ForgotPassword from "./Modules/Authentication/ForgotPassword/ForgotPassword";
import AllCampaigns from './Modules/Campaigns/Campaigns';
import CreateCampaign from './Modules/Campaigns/CreateCampaign';
import Dashboard from "./Modules/Dashboard/Dashboard";
import AOS from 'aos';
import 'aos/dist/aos.css';
import VerifyEmail from "./Modules/Authentication/VerifyEmail/VerifyEmail";
import ResetPassword from "./Modules/Authentication/ResetPassword/ResetPassword";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en.json';
import esTranslation from './es.json';
import eaTranslation from './ea.json';
import AddPassword     from "./Modules/Authentication/ResetPassword/AddPassword";
import AllSegments from './Modules/Segments/AllSegments';
import Segments        from './Modules/Segments/Segments';
import CreateTemplates from './Modules/Settings/Templates/CreateTemplates';
import EditTemplates from './Modules/Settings/Templates/EditTemplates';
import sockets from "./Sockets";
import LeftSection from "./Modules/LeftSection/LeftSection";
import ContactMain from './Modules/Contacts';
import Reports from "./Modules/Reports/Reports";
import Whatsapp from "./Modules/Inbox/SocialChannels/Whatsapp";
import Messenger from "./Modules/Inbox/SocialChannels/Messenger";
import Instagram from "./Modules/Inbox/SocialChannels/Instagram";
import messengerSockets from "./messengerSockets";
import instagramSockets from "./instagramSockets";
import Campaigns from "./Modules/Campaigns/Campaigns";
import Workspace from "./Modules/Settings/Workspace/Workspace";
import Channels from "./Modules/Settings/Channels/Channels";
import Templates from "./Modules/Settings/Templates/Templates";
import User_roles from "./Modules/Settings/User_roles/User_roles";
import Teams from "./Modules/Settings/Teams/Teams";
import TeamInfo from "./Modules/Settings/Teams/TeamInfo";
import Tags from "./Modules/Settings/Tags/Tags";
import Data from "./Modules/Settings/Data_attribute/Data";
import Ai_assistant from "./Modules/Settings/AI_Assistant/Ai_assistant";
import Auto_replies from "./Modules/Settings/Auto_replies/Auto_replies";
import Inbox_rules from "./Modules/Settings/Inbox_rules/Inbox_rules";
import Saved_replies from "./Modules/Settings/Saved_replies/Saved_replies";
import Integration from "./Modules/Settings/Integration/Integration";
import Api_mangement from "./Modules/Settings/Api_mangement/Api_mangement";
import Get_widget from "./Modules/Settings/Get_widget/Get_widget";
import ChatBotBuilder from "./Modules/ChatBotBuilder/ChatBotBuilder";
import AllChatBotBuilder from "./Modules/ChatBotBuilder/AllChatBotBuilder";
import My_profile from "./Modules/Settings/My_profile/My_profile";
import Preference from "./Modules/Settings/Preference/Preference";
import Chatwidget from "./Modules/Settings/Chatwidget/Chatwidget";
import Outofoffice from "./Modules/Settings/Outofoffice/Outofoffice";
import Bottoagent from "./Modules/Settings/Bottoagent/Bottoagent";
import Quickreply from "./Modules/Settings/Quickreply/Quickreply";
import Passwordpolicy from "./Modules/Settings/Passwordpolicy/Passwordpolicy";
import ChangePassword from "./Modules/Settings/ChangePassword/ChangePassword";
import Businesshour from "./Modules/Settings/Businesshour/Businesshour";
import Shopify from "./Modules/Settings/Integration/Shopify";
import OpenApi from "./Modules/Settings/Integration/OpenApi";
import ChannelsTable from "./Modules/Settings/Channels/ChannelsTable";
import Billing from "./Modules/Settings/Billing/Billing";
import BotSettings from "./Modules/ChatBotBuilder/botSettings/botSettings";
import FormBuilder from "./Modules/ChatBotBuilder/botSettings/components/FormBuilder/FormBuilder";
import Formsubmission from "./Modules/ChatBotBuilder/botSettings/components/FormBuilder/Formsubmission";
import CreateForm from "./Modules/ChatBotBuilder/botSettings/components/FormBuilder/CreateForm";
import Apiform from "./Modules/Settings/Api_mangement/Apiform";
import Header from "./Modules/ResuseableComponnets/Header";
import BotInbox from "./Modules/BotInbox/BotInbox";
import CreateRole from "./Modules/Settings/User_roles/CreateRole";
import FileSegment from "./Modules/Segments/FileSegment";
import Createsegment from "./Modules/Segments/Createsegment";
import Entireaudience from "./Modules/Segments/Entireaudience";
import NotifyTable from "./Modules/ResuseableComponnets/NotifyTable";



i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {translation: enTranslation},
            es: {translation: esTranslation},
            ea: {translation: eaTranslation},
        },
        lng: 'en',
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false,
        },
    });

const App = () => {
    const [state, setState] = useState({
        isLock:false,
        isSettingOrProfile:false
    })
    const changeWidth = (isLock, isSettingOrProfile) => {
        setState({...state, isLock, isSettingOrProfile})
    }

    AOS.init();
    sockets()
    messengerSockets()
    instagramSockets()
    useEffect(() => {
        changeWidth(false, false)
    }, []);


    return <Router>
            <div style={{
                display: "flex",
                overflowY: 'hidden',
                height:'100vh',
                background: '#F2F6F9'
            }}>
            <LeftSection changeWidth={changeWidth}/>
            <div style={{flex:1, height:'100vh'}}>
                <div>
                    <Header/>
                </div>
                <div/>
                <Routes>
                    <Route exact path="/" element={<Login/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/forgotPassword" element={<ForgotPassword/>}/>
                    <Route path="/auth/account/reset-password/:id" element={<ResetPassword/>}/>
                    <Route path="/account/add_password" element={<AddPassword/>}/>
                    <Route path="/verify-email" element={<VerifyEmail/>}/>
                    <Route path="/botInbox" element={<BotInbox/>}/>
                    <Route path="/contact" element={<ContactMain/>}/>
                    <Route path="/reports" element={<Reports/>}/>
                    <Route path="/inbox/whatsapp/:inboxChannel" element={<Whatsapp/>}/>
                    <Route path="/inbox/messenger/:inboxChannel" element={<Messenger/>}/>
                    <Route path="/inbox/instagram/:inboxChannel" element={<Instagram/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="/settings/workspace" element={<Workspace/>}/>
                    <Route path="/channels" element={<Channels/>}/>
                    <Route path="/channels/:id" element={<ChannelsTable/>}/>
                    <Route path="/templates" element={<Templates/>}/>
                    <Route path="/editTemplates/:id" element={<EditTemplates/>}/>
                    <Route path="/createTemplates" element={<EditTemplates/>}/>
                    <Route path="/settings/user-roles" element={<User_roles/>}/>
                    <Route path="/Settings/User_roles/CreateRole" element={<CreateRole/>}/>
                    <Route path="/Settings/User_roles/CreateRole/:id" element={<CreateRole />} />
                    <Route path="/settings/teams" element={<Teams/>}/>
                    <Route path="/Settings/Teams/TeamInfo" element={<TeamInfo/>}/>
                    <Route path="/Settings/Teams/TeamInfo/:id" element={<TeamInfo />} />
                    <Route path="/settings/tags" element={<Tags/>}/>
                    <Route path="/settings/data-attributes" element={<Data/>}/>
                    <Route path="/settings/ai-assistant" element={<Ai_assistant/>}/>
                    <Route path="/settings/auto-replies" element={<Auto_replies/>}/>
                    <Route path="/settings/inbox-rules" element={<Inbox_rules/>}/>
                    <Route path="/settings/saved-replies" element={<Saved_replies/>}/>
                    <Route path="/settings/integrations" element={<Integration/>}/>
                    <Route path="/settings/api-management" element={<Api_mangement/>}/>
                    <Route path="/Settings/Api_mangement/Apiform" element={<Apiform/>}/>
                    <Route path="/Settings/Api_mangement/Apiform/:id" element={<Apiform/>}/>
                    <Route path="/settings/get-widget" element={<Get_widget/>}/>
                    <Route path="/settings/Billing" element={<Billing/>}/>
                    <Route path="/settings/my_profile" element={<My_profile/>}/>
                    <Route path="/settings/Preference" element={<Preference/>}/>
                    <Route path="/settings/Chat-Widgete" element={<Chatwidget/>}/>
                    <Route path="/settings/Out-of-office" element={<Outofoffice/>}/>
                    <Route path="/settings/Bot-to-agent" element={<Bottoagent/>}/>
                    <Route path="/settings/Quick-reply" element={<Quickreply/>}/>
                    <Route path="/settings/Paasword-policy" element={<Passwordpolicy/>}/>
                    <Route path="/settings/Change-Password" element={<ChangePassword/>}/>
                    <Route path="/settings/Business-hours" element={<Businesshour/>}/>
                    <Route path="/chat-bot/:id" element={<ChatBotBuilder/>}/>
                    <Route path="/chat-bot/templates/:id" element={<ChatBotBuilder/>}/>
                    <Route path="/chat-bot" element={<AllChatBotBuilder/>}/>
                    <Route path="/settings/integrations/shopify" element={<Shopify/>}/>
                    <Route path="/settings/integrations/openApi" element={<OpenApi/>}/>
                    <Route path="/chat-bot/settings" element={<BotSettings/>}/>
                    <Route path="/segments" element={<AllSegments/>}/>
                    <Route path="/segments/create" element={<Segments/>}/>
                    <Route path="/Segments/list_upload/:name" element={<FileSegment/>}/>
                    <Route path="/Segments/create/:type/:name" element={<Entireaudience/>}/>
                    <Route path="/Segments/edit/:id" element={<Entireaudience/>}/>
                    <Route path="/campaigns" element={<AllCampaigns/>}/>
                    <Route path="/campaigns/:id" element={<CreateCampaign/>}/>
                    <Route path="/create-campaign" element={<CreateCampaign/>}/>
                    <Route path="/chat-bot/settings/createForm" element={<CreateForm/>}/>
                    <Route path="/chat-bot/settings/editForm/:id" element={<CreateForm/>}/>
                    <Route path="/ChatBotBuilder/botSettings/components/FormBuilder/Formsubmission" element={<Formsubmission/>}/>
                    <Route path="/ChatBotBuilder/botSettings/components/FormBuilder/Formsubmission/:id" element={<Formsubmission/>}/>
                  <Route path="/ResuseableComponnets/NotifyTable" element={<NotifyTable/>}/>
                </Routes>
            </div>
            </div>
        </Router>
}
export default App