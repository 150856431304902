import { SearchOutlined, UnorderedListOutlined }                               from "@ant-design/icons";
import AccountCircleIcon                                                       from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon                                                       from '@mui/icons-material/AssignmentInd';
import ListItem                                                                from "@mui/material/ListItem";
import ListItemText                                                            from "@mui/material/ListItemText";
import { Dropdown, Modal, Button, Input, Space, Table, Tooltip, Menu, Select } from "antd";
import moment                                                                  from "moment/moment";
import React, { useEffect, useRef, useState }                                  from "react";
import Highlighter                                                             from "react-highlight-words";
import { FaCircleDown }                                                        from "react-icons/fa6";
import { useDispatch, useSelector }                                            from 'react-redux';
import {
  assignAgent,
  assignToMe,
  conversationTimer,
  forwardChatToUser,
  getAllAgents,
  getAllForwarders,
  getLogs,
  searchAgents,
  readAllMessages,
  removeFromSpam
}                                                                              from "../../Inbox/Apis";
import ActionsCreators                                                         from '../../Inbox/states';
import { removeChat }                                                          from '../../Inbox/states/Actions';
import Loader                                                                  from "../../ResuseableComponnets/Loader";
import openNotificationWithIcon
                                                                               from "../../ResuseableComponnets/Toaster";
import { getAllActivatedTags, assignTagToChat }                                from '../Tags/API/Api';
import { getAllTeams }                                                         from "./API/API";

const { Option } = Select;
const ChatBotHeader = (props) => {
  const [state, setState] = useState({
    status: "",
    allAgents: [],
    timer: '00:00',
    timerMessage: "",
    isModal: false,
    teamsData: [],
    logsModal: false,
    logsData: [],
    isLoader: false,
    selectedTeam: null,
    selectedMember: null,
    teamAgentId: "",
    teamId: "",
    note: "",
    searchPopper: false,
    mode: '',
    myTeams: [],
    loaderInModal: false,
    tagsModal: false,
    allTags: [],
    assignedTag: null,
    assigningStatus: null
  })
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  let dispatch = useDispatch()
  let myTeams = []
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const assignAgentFunc = () => {
    let data = {
      conversationId: props?.allChatInfo?.conversationId,
      agentID: state.teamAgentId,
      teamID: state.teamId,
      notes: state.note
    }
    assignAgent(data).then(() => {
      props.showChat(false)
      dispatch(ActionsCreators.getAllWhatsappConversations())
      setState({ ...state, isModal: false })
      openNotificationWithIcon(
        'success',
        'Success message',
        'Agent assign successfully'
      )
    }).catch(err => {
      openNotificationWithIcon(
        'error',
        'Error message',
        err?.response?.data?.message
      )
      setState({ ...state, isModal: false })
    })
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={ { padding: 8 } } onKeyDown={ (e) => e.stopPropagation() }>
        <Input
          ref={ searchInput }
          placeholder={ `Search ${ dataIndex }` }
          value={ selectedKeys[0] }
          onChange={ (e) => setSelectedKeys(e.target.value ? [e.target.value] : []) }
          onPressEnter={ () => handleSearch(selectedKeys, confirm, dataIndex) }
          style={ { marginBottom: 8, display: 'block' } }
        />
        <Space>
          <Button
            onClick={ () => clearFilters && handleReset(clearFilters) }
            size="small"
            style={ { width: 90 } }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={ () => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            } }
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={ () => {
              close();
            } }
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={ { color: filtered ? '#1890ff' : undefined } }/>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={ { backgroundColor: '#ffc069', padding: 0 } }
          searchWords={ [searchText] }
          autoEscape
          textToHighlight={ text ? text.toString() : '' }
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Current State",
      dataIndex: 'currentState',
      key: 'currentState',
      width: '20%',
      ...getColumnSearchProps('currentState'),
    },
    {
      title: "Previous State",
      dataIndex: 'prevState',
      key: 'prevState',
      width: '20%',
      ...getColumnSearchProps('prevState'),
    },
    {
      title: "Log Time",
      dataIndex: 'timestamp',
      key: 'timestamp',
      ...getColumnSearchProps('timestamp'),
    },
    {
      title: "Assigned To",
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      ...getColumnSearchProps('assignedTo'),
    },
    {
      title: "Notes",
      dataIndex: 'notes',
      key: 'notes',
      ...getColumnSearchProps('notes'),
    },
  ];

  let allForwardersKey = [
    {
      title: "From",
      dataIndex: 'from',
      key: 'from',
      width: '20%',
      ...getColumnSearchProps('from'),
    },
    {
      title: "To",
      dataIndex: 'to',
      key: 'to',
      width: '20%',
      ...getColumnSearchProps('to'),
    },
    {
      title: "Team",
      dataIndex: 'team',
      key: 'team',
      width: '20%',
      ...getColumnSearchProps('team'),
    },
    {
      title: "Time",
      dataIndex: state.mode === 'allAgents' ? 'assignAt' : 'forwardAt',
      key: state.mode === 'allAgents' ? 'assignAt' : 'forwardAt',
      ...getColumnSearchProps(state.mode === 'allAgents' ? 'assignAt' : 'forwardAt'),
    },
    {
      title: "Notes",
      dataIndex: 'notes',
      key: 'notes',
      width: '20%',
      ...getColumnSearchProps('notes'),
    },
  ];

  allForwardersKey = state.mode === "allAgents" ? allForwardersKey.filter(item => item.title !== "Team") : allForwardersKey

  const assignTagFunc = (tag) => {
    let data = {
      conversationId: props?.allChatInfo?.conversationId,
      tag
    }
    assignTagToChat(data).then(() => {
      setState({ ...state, assignedTag: tag })
      props.getChatInformation(props?.allChatInfo?.conversationId)
      openNotificationWithIcon('success',
        'Success Message',
        'Tag assign successfully'
      )
    }).catch(() => {
      setState({ ...state, isLoader: false })
      openNotificationWithIcon('error',
        'Error Message',
        'Error while assigning tag'
      )
    })
  }

  const handleChange = async (value) => {
    value === 'solved' && setState({ ...state, assigningStatus: value })
    if (value === 'solved' && !props?.allChatInfo?.assignTag && !state.assignedTag) {
      await getAllActivatedTags().then(res => {
          setState(prevState => ({
            ...prevState,
            allTags: res.data.data,
            tagsModal: true
          }))
        }
      ).catch(() => console.log())
      return true
    }

    let data = {
      conversationId: props?.allChatInfo?.conversationId,
      status: value
    }
    await props.assignStatusFunc(data)
      .then(() => {
        setState({
          ...state,
          status: state.assigningStatus,
          tagsModal: false
        })
        value === 'solved' && dispatch(removeChat({ conversationId: props.allChatInfo.conversationId }))
        value === 'solved' && props.showChat(false)
        props.getChatInformation(props?.allChatInfo?.conversationId)
        openNotificationWithIcon(
          'success',
          'Success message',
          'Status changed successfully'
        )
      }).catch(err => openNotificationWithIcon(
        'error',
        'Error message',
        err?.response?.data?.message
      ))
  };

  const conversationTimerFunc = () => {
    let conversationId = props?.allChatInfo?.conversationId
    conversationTimer(conversationId)
      .then(res => {
        setState({
          ...state,
          timer: res?.data?.data,
          timerMessage: ''
        })
      })
      .catch(() => {
          setState(({
            ...state,
            timerMessage: "Chat has been expired."
          }))
        }
      )
  }
  const timeOutTimer = () => {
    conversationTimerFunc()
    setTimeout(() => {
      timeOutTimer()
    }, 60000)
  }

  const assignToMeFunc = async () => {
    assignToMe({ conversationId: props?.allChatInfo?.conversationId }).then((res) => {
        props.getChatInformation(props?.allChatInfo?.conversationId)
        readAllMessages(props?.allChatInfo?.conversationId).then(() => console.log()).catch(() => console.log())
        props.changeInboxTab('in-progress')
        // dispatch(ActionsCreators.getAllWhatsappConversations())
        openNotificationWithIcon('success',
          'Success',
          'Chat assigned to you successfully'
        )
      }
    ).catch((err) => {
      props.loaderInChatPanel(false)
      openNotificationWithIcon('error',
        'Error',
        err?.response?.data?.message
      )
    })
  }
  const getLogsFunc = () => {
    setState({
      ...state,
      loaderInModal: true,
      logsModal: true,
      mode: 'logs'
    })
    getLogs(props?.allChatInfo?.conversationId).then(res => {
      setState(prevState => ({
        ...prevState,
        loaderInModal: false,
        logsData: res?.data?.data.map((obj) => ({
          ...obj,
          currentState: obj?.currentState.replace('-', ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
          prevState: obj?.prevState.replace('-', ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
          timestamp: moment(obj?.timestamp).format('MMMM Do YYYY, h:mm a'),
          assignedTo: obj?.assignedTo?.firstName
        })),
      }))
    }).catch(() => setState({ ...state, loaderInModal: false }))
  }

  const getTeamsFunc = (mode) => {
    setState({
      ...state,
      loaderInModal: true,
      isModal: true,
      mode: mode
    })
    getAllTeams()
      .then(res => {
        setState(() => ({
          ...state,
          teamsData: res.data.data,
          loaderInModal: false,
          isModal: true,
          mode
        }))
        myTeams = res.data.data
      })
      .catch((err) => {
        setState({ ...state, isLoader: false })
        openNotificationWithIcon('error',
          'Error Message',
          err?.response?.data?.message
        )
      })
  }

  const getAllForwadersFunc = () => {
    setState({ ...state, mode: 'allForwarders', isModal: true, loaderInModal: true })
    getAllForwarders(props?.allChatInfo?.conversationId).then(res => setState(prevState => ({
      ...prevState,
      loaderInModal: false,
      logsData: res?.data?.data.map((obj) => ({
        ...obj,
        from: obj.from.email,
        to: obj.to.email,
        forwardAt: moment(obj?.forwardAt).format('MMMM Do YYYY, h:mm a'),
        team: obj?.team?.name,
        notes: obj?.notes
      })),
    }))).catch(err => console.log(err, 'errorrrr'))
  }
  const getAllAgentsFunc = () => {
    setState({
      ...state,
      isModal: true,
      loaderInModal: true,
      mode: 'allAgents'
    })
    getAllAgents(props?.allChatInfo?.conversationId).then(res => setState(prevState => ({
      ...prevState,
      loaderInModal: false,
      logsData: res?.data?.data.map((obj) => ({
        ...obj,
        from: obj?.from?.email,
        to: obj?.to?.email,
        assignAt: moment(obj?.forwardAt).format('MMMM Do YYYY, h:mm a'),
        notes: obj?.notes
      }))
    }))).catch(err => setState({
      ...state,
      isModal: false,
      loaderInModal: false,
      mode: ''
    }))
  }

  const forwardChatToUserFunc = () => {
    let data = {
      conversationId: props?.allChatInfo?.conversationId,
      agentID: state.teamAgentId,
      teamID: state.teamId,
      notes: state.note
    }
    forwardChatToUser(data).then(res => {
      setState({ ...state, isModal: false })
      openNotificationWithIcon('success',
        'Success Message',
        'Chat forwarded successfully'
      )
    }).catch(err => {
        openNotificationWithIcon('error',
          'Error Message',
          err?.response?.data?.message
        )
        setState({ ...state, isModal: false })
      }
    )
  }


  let selectedTeamVar = state.teamsData.find(item => state?.teamId === item._id)?.users.filter(item => item?.email !== props.allChatInfo?.assign?.to?.email)
  let selectedChat = useSelector(state => state?.inbox?.allWhatsappConversations)?.find(item => item?.conversationId === props.allChatInfo?.conversationId)
  let myRole = useSelector(state => state?.me.me.role)

  let statusValue = selectedChat?.status

  const removeSpam = async () => {
    removeFromSpam(props?.allChatInfo?.conversationId).then(() => {
        props.getChatInformation(props?.allChatInfo?.conversationId)
        readAllMessages(props?.allChatInfo?.conversationId).then(() => console.log()).catch(() => console.log())
        props.changeInboxTab('in-progress')
        openNotificationWithIcon('success',
          'Success',
          'Chat assigned to you successfully'
        )
      }
    ).catch((err) => {
      props.loaderInChatPanel(false)
      openNotificationWithIcon('error',
        'Error',
        err?.response?.data?.message
      )
    })
  }

  useEffect(() => {
    conversationTimerFunc()
  }, [props.allMessagesLength]);

  useEffect(() => {
    conversationTimerFunc()
  }, [])

  useEffect(() => {
    props.channel === "whatsapp" && timeOutTimer()
    searchAgents().then(res => {
        setState({
          ...state,
          allAgents: res.data.data
        })
      }
    )
      .catch(err => console.log(err))
  }, [props?.allChatInfo?.id]);

  useEffect(() => {
    setState({
      ...state,
      status: selectedChat?.status,
      selectedTeam: props?.allChatInfo?.assign?.team?.name
    })
  }, []);

  return state.isLoader ? <Loader/> : <div style={ {
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: '#CAD9F0',
    borderRadius: "12px 12px 2px 2px",
    padding: 8
  } }>
    <h5>Assigned to: { props.allChatInfo?.assign?.to?.email }</h5>
    { props.channel === "whatsapp" && <div style={ {
      padding: '5px 8px 5px 8px',
      borderRadius: 4,
      fontWeight: 550,
      backgroundColor: "white"
    } }>
      {
        !!state?.timerMessage ? state.timerMessage : state.timer
      }
    </div> }
    {
      !!props?.isBotInbox ? <div/> :
        <div style={ {
          display: "flex",
          gap: 6,
          height: "fit-content",
          alignItems: "center"
        } }>
          <Select
            defaultValue={ statusValue }
            value={ 'Mark Status' }
            style={ { display: !props.showMessageBar ? 'none' : 'block' } }
            onChange={ handleChange }
            options={
               (selectedChat?.status === 'solved' || selectedChat?.status === 'assigned' || selectedChat?.status === 'exit') ? [] :
                [{ value: 'solved', label: 'Closed', disabled: state.status === 'solved' ? true : false },
                  { value: 'spam', label: 'Spam', disabled: state.status === 'spam' ? true : false }
                ] }
          />

          {
            console.log(props.allChatInfo.status, 9876311111)
          }

          {
            props.allChatInfo.status === "spam" &&
            <Button type={ "text" } onClick={ removeSpam }>Remove from spam</Button>
          }

          <Tooltip
            placement="top"
            title={ "Assign" }>
            <AssignmentIndIcon style={ {
              display: !props.showMessageBar && myRole !=="admin" ? 'none' : 'block',
              color: 'grey',
              cursor: "pointer"
            } } onClick={ () => getTeamsFunc('assign') }/>
          </Tooltip>
          <Tooltip
            placement="top"
            title={ "Contact info" }>
            <AccountCircleIcon
              style={ { color: 'grey', fontSize: 20, cursor: "pointer" } }
              onClick={ () => {
                setState({ ...state, isModal: false })
                props.openRightSectionOpen()
              } }/>
          </Tooltip>
          <Tooltip
            placement="top"
            title={ "Assign to me" }>
            <FaCircleDown style={ {
              fontSize: 17,
              display: props.showMessageBar || statusValue !== 'open' ? 'none' : 'block',
              color: 'grey', cursor: "pointer"
            } } onClick={ () => assignToMeFunc() }/>
          </Tooltip>
          <Dropdown overlay={ (
            <Menu>
              <Menu.Item>
                <div style={ { display: !props.showMessageBar && myRole !=="admin" ? 'none' : 'block' } }
                     onClick={ () => getTeamsFunc('forwardChat') }> Forward
                </div>
              </Menu.Item>
              <Menu.Item>
                <div onClick={ () => getLogsFunc() }> Chat log</div>
              </Menu.Item>
              <Menu.Item>
                <div onClick={ () => props.openRangePicker() }> Export</div>
              </Menu.Item>
              <Menu.Item>
                <div onClick={ () => getAllForwadersFunc() }> All Forwarders</div>
              </Menu.Item>
              <Menu.Item>
                <div onClick={ () => getAllAgentsFunc() }> All Agents</div>
              </Menu.Item>
            </Menu>
          ) } trigger={ ['click'] }>
            <a onClick={ (e) => e.preventDefault() }>
              <UnorderedListOutlined style={ {
                fontSize: '14px',
                cursor: 'pointer',
                color: 'grey'
              } }/>
            </a>
          </Dropdown>
        </div>
    }

    <Modal
      title={ state.mode === 'assign' ? "Assign Conversation" : state.mode === 'forwardChat' ? 'Forward Chat' : state.mode === 'allAgents' ? 'All agents' : 'All Forwarders' }
      open={ state.isModal }
      okButtonProps={ { disabled: !state.teamId || !state.teamAgentId } }
      onOk={ () => state.mode === 'assign' ? assignAgentFunc() : forwardChatToUserFunc() }
      onCancel={ () => setState({
        ...state,
        isModal: false,
        logsData: [],
        mode: "",
        teamId: "",
        teamsData: [],
        teamAgentId: ""
      }) }
      destroyOnClose={ () => setState({
        ...state,
        isModal: false
      }) }
      style={ { minWidth: (state.mode === 'allForwarders' || state.mode === 'allAgents') ? '95%' : '' } }
    >
      {
        state.loaderInModal ?
          <Loader height={ '10vh' }/> : (state.mode === 'allForwarders' || state.mode === 'allAgents') ?
            <Table
              pagination={ pagination }
              onChange={ (page) => {
                setPagination(page);
              } }
              style={ { marginTop: '2%' } }
              columns={ allForwardersKey }
              dataSource={ state.logsData }
            /> :
            <div>
              <Select
                style={ { marginTop: "1%", width: '100%' } }
                value={ state.teamId || undefined }
                placeholder="Select team"
                onChange={ e => {
                  setState({
                    ...state,
                    teamId: e,
                    teamAgentId: ''
                  })
                } }
                options={
                  !!state?.teamsData && state.teamsData.length > 0 &&
                  state.teamsData.map(item => ({
                    label: item.name,
                    value: item.id
                  }))
                }
              />
              <br/>
              <br/>
              <Select
                style={ { marginTop: "1%", width: '100%' } }
                value={ state.teamAgentId || undefined }
                placeholder="Please select a team member"
                onSelect={ (e) => {
                  setState({
                    ...state,
                    teamAgentId: e,
                  });
                } }
                options={
                  selectedTeamVar?.map((item) => ({
                    value: item.id,
                    label: item.email,
                  })) || []
                }
              />

              <Input.TextArea onChange={ (e) => setState({ ...state, note: e.target.value }) }
                              style={ { marginTop: 20 } }
                              placeholder={ "Enter Note Here" }/>
            </div>
      }
    </Modal>

    <Modal
      title={ state.mode === 'details' ? 'Details' : state.mode === 'logs' && "Conversation Logs" }
      open={ state.logsModal }
      onCancel={ () => setState({ ...state, logsModal: false }) }
      style={ { minWidth: state.mode === 'details' ? '' : state.mode === 'logs' && "95%" } }
      footer={ null }
      afterClose={ () => setState({ ...state, mode: '' }) }
    >
      {
        state.mode === 'details' ?
          <div>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={ 'Current state' }
                secondary={ <div style={ { fontSize: 14 } }>{ selectedChat?.status } </div> }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={ 'Assigned to' }
                secondary={ <div style={ { fontSize: 14 } }> { props?.allChatInfo?.assign?.to?.email }</div> }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={ 'Assigned by' }
                secondary={ <div style={ { fontSize: 14 } }> { props?.allChatInfo?.assign?.from?.email }</div> }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={ 'Notes' }
                secondary={ <div style={ { fontSize: 14 } }>{ props?.allChatInfo?.assign?.notes } </div> }
              />
            </ListItem>
          </div> :
          state.loaderInModal && state.mode === 'logs' ? <Loader height={ '10vh' }/> : state.mode === 'logs' &&
            <Table
              pagination={ pagination }
              onChange={ (page) => {
                setPagination(page);
              } }
              style={ { marginTop: '2%' } }
              columns={ columns }
              dataSource={ state.logsData }
            />
      }
    </Modal>

    <Modal
      title={ 'Assign tag' }
      open={ state.tagsModal }
      onCancel={ () => setState({ ...state, tagsModal: false }) }
      onOk={ () => handleChange(state.assigningStatus) }
      okButtonProps={ { disabled: !state.assignedTag } }
      okText={ 'Submit' }
    >
      <Select
        defaultValue={ state.assignedTag }
        value={ state.assignedTag }
        style={ { width: '100%', display: !props.showMessageBar ? 'none' : 'block' } }
        onChange={ assignTagFunc }
        options={ state.allTags.map(item => (
          { value: item.name, label: item.name }
        )) }
      />
    </Modal>

  </div>
}
export default ChatBotHeader