import React, {useEffect, useRef, useState} from 'react';
import {Input, Button, Space,Select, Table} from 'antd';
import {IoIosAddCircleOutline} from 'react-icons/io';
import EditIcon from '@mui/icons-material/Edit';
import {MoreOutlined, SearchOutlined, CaretDownOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import {getAllForms, activateForm, createForm, deActivateAllForm, deActivateForm, updateForm, getForm, getActivatedForm} from './Apis/api'
import {Dropdown, Modal, Menu} from 'antd';
import Loader from "../../../../ResuseableComponnets/Loader";
import RemoveIcon from "@mui/icons-material/Remove";
import DoneIcon from '@mui/icons-material/Done';
import moment from "moment";
import {useNavigate} from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { Link } from 'react-router-dom'



const FormBuilder = () => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        openDialogue: false,
        name: "",
        text: "",
        isLoader: false,
        data: [],
        selectedData: null,
        mode: "",
        activatedFilter:'All forms',
        modal:false,
        id:"",
        filterName:"Please Select"

    })
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 8,
    });

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const navigate = useNavigate()

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: t("Name"),
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...getColumnSearchProps('title'),
        },
        {
            title: t("Text"),
            dataIndex: 'description',
            key: 'description',
            width: '30%',
            ...getColumnSearchProps('description'),
        },
        {
            title: t("Status"),
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps('status')
        },
        {
            title: t("Created At"),
            dataIndex: 'created',
            key: 'created',
            ...getColumnSearchProps('created')
        },
        {
            title: t("submission"),
            dataIndex: 'submission',
            render: (_, record) => (
              <Link to={`/ChatBotBuilder/botSettings/components/FormBuilder/Formsubmission/${record._id}`}>
                <FaEye style={{ fontSize: '14px', cursor: 'pointer' }} />
              </Link>
            )
          },
        {
            title: t("Actions"),
            dataIndex: 'action',
            key: 'action',
            ...getColumnSearchProps('action'),
        },
         
    ];

    const updatedArray = state.data.map((obj) => ({
        ...obj,
        'status': obj.status ? "Active" : "De-activated",
        action: (
            <Dropdown overlay={(
                <Menu>
                    <Menu.Item key="1">
                        <div onClick={() => setState({
                            ...state,
                            openDialogue: true,
                            selectedData: obj,
                            mode: "activate"
                        })}>
                            <DoneIcon/> {t("ActivateFlow")}
                        </div>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <div onClick={() => setState({
                            ...state,
                            openDialogue: true,
                            selectedData: obj,
                            mode: "de-activate"
                        })}>
                            <RemoveIcon/> {t("De-activateFlow")}
                        </div>
                    </Menu.Item>
                    <Menu.Item key="3">
                        <div onClick={()=> navigate(`/chat-bot/settings/editForm/${obj.id}`)}>
                            <EditIcon/> Edit
                        </div>
                    </Menu.Item>
                </Menu>
            )} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                    <MoreOutlined style={{fontSize: '14px'}}/>
                </a>
            </Dropdown>
        )
    }))

    const getAllFunc = () => {
        setState({
            ...state,
            isLoader: true, 
        });
        getAllForms().then(res => setState({
            ...state,
            openDialogue: false,
            isLoader: false,
            id:res.data.data[0]?.id,
            data: res?.data?.data.map((obj) => ({
                ...obj,
                created: moment(obj?.created).format('MMMM Do YYYY, h:mm a'),
            }) || []
       , console.log(res,5454454)),
            mode: "",
            activatedFilter: "All Forms",
            
        })).catch((err) => console.log(err))
    }
    const saveFunc = () => {
        setState({...state, isLoader: true})
        let data = {
            name: state.name,
            text: state.text
        }
        createForm(data).then(() => {
            getAllFunc()
        }).catch(() => setState({...state, isLoader: false}))
    }

    const deActivateFunc = ()=> {
        let id = state.selectedData.id
        deActivateForm(id)
            .then(()=>getAllFunc())
            .catch(()=>setState({...state, isLoader: false}))
    }

    const deActivateAllFunc = ()=> {
        setState({...state, isLoader: true})
        deActivateAllForm()
            .then(()=>getAllFunc())
            .catch(()=>setState({...state, isLoader: false}))
    }

    const activateFunc = ()=> {
        let id = state.selectedData.id
        activateForm(id)
            .then(()=>getAllFunc())
            .catch(()=>setState({...state, isLoader: false}))
    }

    const getAllactivateFormsFunc = ()=> {
        setState({...state, isLoader: true})
        getActivatedForm()
            .then(res => setState({
                ...state,
                openDialogue: false,
                isLoader: false,
                data: res?.data?.data.map((obj) => ({
                    ...obj,
                    created: moment(obj?.created).format('MMMM Do YYYY, h:mm a'),
                })),
                mode: "",
                activatedFilter: "Activated Forms"
            }))
            .catch(()=>setState({...state, isLoader: false}))
    }

    const updateFunc = ()=> {
        let id = state.selectedData.id
        let data = {
            name:state.name,
            text:state.text
        }
        updateForm(id, data)
            .then(()=>getAllFunc())
            .catch(()=>setState({...state, isLoader: false}))
    }

    console.log(state.activatedFilter, 77771111111)

    useEffect(() => {
        getAllFunc()
    }, []);

    const handleChange = (value) => {
        if (value === "1") getAllFunc();
        if (value === "2") getAllactivateFormsFunc();
        if (value === "3") deActivateAllFunc();
    }
    
    return (
        state.isLoader ? <Loader/> :
            <>
                <div className="synctic-module-container-tabs">
                    <div className='cards-box'
                         style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <div className="synctic-module-def">
                            <div className='heading'> {t("Form Builder")}</div>
                        </div>
                        <div style={{
                            display:'flex',
                            justifyContent:'space-between',
                            gap:20,
                            alignItems:'center'
                        }}>
                                <Dropdown
                                menu={{
                                    items: [
                                    { key: '1', label: 'All forms', onClick: () => getAllFunc() },
                                    { key: '2', label: 'Activated forms', onClick: () => getAllactivateFormsFunc() },
                                    { key: '3', label: 'Deactivate All', onClick: () => deActivateAllFunc() },
                                    ],
                                }}
                                placement="bottom"
                                trigger={["click"]}
                                >
                                <Button>
                                    {state.filterName || "Please Select"} 
                                    <CaretDownOutlined />
                                </Button>
                                </Dropdown>
                            <Button
                                type="primary"
                                style={{float: 'right', marginTop: '1%'}}
                                onClick={()=> navigate("/chat-bot/settings/createForm")}
                            >
                                <IoIosAddCircleOutline
                                    style={{fontSize: '15px', color: '#fff', marginRight: '12px', marginTop: '-2%'}}
                                />
                                {t("Add Form")}
                            </Button>
                        </div>
                    </div>
                    <div style={{marginTop: 10}}>
                        <Table
                            pagination={pagination}
                            onChange={(page) => {
                                setPagination(page);
                            }}
                            columns={columns}
                            dataSource={updatedArray}
                        />
                    </div>
                </div>
                <Modal
                    title={ state.mode==="activate" ?
                        t("Areyousureyou") :
                        state.mode==="de-activate" ?
                            t("Areyousureyoude-activate") :
                            state.mode==="delete" ?
                                t("Areyousuredelete"):
                                state.mode==="update" ?
                                    "Update":
                                    t("AddNewReply")
                    }
                    open={state.openDialogue}
                    onCancel={() => setState({
                        ...state,
                        openDialogue: false,
                        selectedData: null,
                        mode:""
                    })}
                    onOk={() => {
                        state.mode==="de-activate" ? deActivateFunc() :
                            state.mode==="activate" ? activateFunc() :
                                    state.mode==="update" ? updateFunc() : saveFunc()
                    }}>
                    {
                        (state.mode==="update" || state.mode==="add") &&
                        <>
                            <Input style={{marginTop: 10}} value={state.name}
                                   onChange={(e) => setState({...state, name: e.target.value})}
                                   placeholder={t("Enter-Name")}/>
                            <Input style={{marginTop: 10}} value={state.text}
                                   onChange={(e) => setState({...state, text: e.target.value})}
                                   placeholder={t("Entertext")}/>
                        </>
                    }
                </Modal>
            </>
    );
};

export default FormBuilder;
