import React, {useEffect, useState} from 'react';
import {SiShopify} from "react-icons/si";
import {Input, Button} from 'antd';
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import "./Shopify.scss";
import {getOpenApi, saveOpenApi} from "./API/Api";
import Loader from "../../ResuseableComponnets/Loader";
import { useTranslation } from 'react-i18next';

const OpenApi = () => {
    const [state, setState] = useState({
        secretKey: ""
    })
    const [isLoader, setIsLoader] = useState(false)

    const saveFunc = () => {
        let data  = {
            secretKey: state.secretKey
        }
        setIsLoader(true)
        saveOpenApi(data).then(()=>getFunc()).catch(()=>setIsLoader(false))
    }
    const getFunc = () => {
        setIsLoader(true)
        getOpenApi().then(res=> {
            let data = res.data.data
            setState({
                ...state,
                secretKey: data.secretKey
            })
            setIsLoader(false)
        }).catch(()=>setIsLoader(false))
    }

    useEffect(() => {
        getFunc()
    }, []);
    const { t } = useTranslation();
    return (
        isLoader ? <Loader/> :
            <div className='synctic-module-container' style={{display: "flex", flexDirection: "column"}}>
                <SettingsHeader heading="Shopify"
                                icon={<SiShopify className='icon' style={{color: "#319531"}}/>}/>
             <div className='cards-box' style={{marginTop: '1%',}}>
             <div className='Shopify-container'>
                    <div style={{display: "flex", flexDirection: "column", marginTop: "2%"}}>
                        <label className='Shopify-Store'>{t("SecretKey")}</label>
                        <Input
                            onChange={(e)=> setState({...state, secretKey: e.target.value })
                            }
                            value={state.secretKey}
                            placeholder={`Enter secret key here`}
                            autoComplete="off"
                        />
                    </div>
                    <Button type="primary" style={{marginTop: "3%", backgroundColor: 'rgb(9 179 67)'}}
                            onClick={()=>saveFunc()}>{t("Save")}</Button>
                </div>
                </div>
            </div>
    );
}

export default OpenApi;
