import React from 'react';
import { Button }      from "antd";
import { useNavigate } from 'react-router-dom';

const Header = ({ name, summary, Back, Continue, clickFunction }) => {
  const navigate = useNavigate()
  return (
    <div
      className="cards-box-data"
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <h3 style={{ color: "#333", marginTop: 12 }}>{name}</h3>
      <div style={{ display: "flex", gap: 10, marginTop: "10px" }}>
        <Button type="primary" danger onClick={()=>navigate("/segments")}>
          {summary}
        </Button>
        <Button type="primary" danger onClick={()=>navigate("/segments/create")}>
          {Back}
        </Button>
        <Button type="primary" onClick={()=>clickFunction()}>
          {Continue}
        </Button>
      </div>
    </div>
  );
};

export default Header;
