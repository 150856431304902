import React, {useEffect, useRef, useState} from 'react';
import { BsFillTagFill } from "react-icons/bs";
import {Input, Button, Space, Table, Modal, Dropdown, Menu} from 'antd';
import {MoreOutlined, SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from "@mui/icons-material/Done";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../ResuseableComponnets/Loader";
import {activateChannel, deactivateChannel, deleteChannelById, updateContact} from "./API/Api";

const TableContent = (props) => {
    const [state, setState] = useState({
        openDialogue: false,
        name: "",
        isLoader: false,
        data: [],
        selectedData: null,
        mode: "",
        accessToken:""
    })
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 8,
    });

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Name",
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...getColumnSearchProps('title'),
        },
        {
            title: "Status",
            dataIndex: 'status',
            key: 'status',
            width: '30%',
            ...getColumnSearchProps('body'),
        },
        {
            title: "Actions",
            dataIndex: 'action',
            key: 'action',
            ...getColumnSearchProps('action'),
        },
    ];

    const updatedArray = state.data.map((obj) => ({
        ...obj,
        'status': obj.status ? "Active" : "De-activated",
        action: (
            <Dropdown overlay={(
                <Menu>
                    <Menu.Item key="1">
                        <div onClick={() => setState({
                            ...state,
                            openDialogue: true,
                            selectedData: obj,
                            mode: "activate"
                        })}>
                            <DoneIcon/> Activate tag
                        </div>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <div onClick={() => setState({
                            ...state,
                            openDialogue: true,
                            selectedData: obj,
                            mode: "de-activate"
                        })}>
                            <RemoveIcon/> De-activate tag
                        </div>
                    </Menu.Item>
                    <Menu.Item key="3">
                        <div onClick={() => setState({
                            ...state,
                            openDialogue: true,
                            selectedData: obj,
                            mode: "delete"
                        })}>
                            <DeleteIcon/> Delete tag
                        </div>
                    </Menu.Item>
                    <Menu.Item key="4">
                        <div onClick={() => setState({
                            ...state,
                            openDialogue: true,
                            selectedData: obj,
                            mode: "update",
                            name:obj.name,
                            text:obj.text,
                            accessToken: obj.accessToken
                        })}>
                            <EditIcon/> Update
                        </div>
                    </Menu.Item>
                </Menu>
            )} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                    <MoreOutlined style={{fontSize: '14px'}}/>
                </a>
            </Dropdown>
        )
    }))
    const getAllFunc = () => {
        setState({...state, isLoader: true})
        props.getAllData().then(res => setState({...state, data:res.data.data, isLoader: false, openDialogue:false})).catch(() =>setState({...state, isLoader: false, openDialogue:false}))
    }

    const saveFunc = () => {
        setState({...state, isLoader: true})
        let data = {
            name: state.name,
            accessToken: state.accessToken
        }
        props.addChannel(data).then(() => {
            getAllFunc()
        }).catch(() => setState({...state, isLoader: false}))
    }

    const deActivateFunc = ()=> {
        let id = state.selectedData.id
        deactivateChannel(id)
            .then(()=>getAllFunc())
            .catch(()=>setState({...state, isLoader: false}))
    }

    const activateFunc = ()=> {
        let id = state.selectedData.id
        activateChannel(id)
            .then(()=>getAllFunc())
            .catch(()=>setState({...state, isLoader: false}))
    }

    const updateFunc = ()=> {
        let id = state.selectedData.id
        let data = {
            name:state.name,
            accessToken:state.accessToken,
            channelType:props.mode
        }
        updateContact(id, data)
            .then(()=>getAllFunc())
            .catch(()=>setState({...state, isLoader: false}))
    }

    const deleteFunc = ()=> {
        let id = state.selectedData.id
        deleteChannelById(id)
            .then(()=>getAllFunc())
            .catch(()=>setState({...state, isLoader: false}))
    }

    useEffect(() => {
        getAllFunc()
    }, []);


    return (
        state.isLoader ? <Loader/> :
            <div className="synctic-module-container">
                <Button
                    type="primary"
                    onClick={() => setState({
                        ...state,
                        mode: 'add',
                        openDialogue: true,
                        name: "",
                        accessToken: ''
                    })}
                    size="small"
                    style={{ width: 140, float:"right" }}
                >
                    Add Channel
                </Button>
                <Modal
                    title={ state.mode==="activate" ?
                        "Are you sure you want to activate this?" :
                        state.mode==="de-activate" ?
                            "Are you sure you want to de-activate this?" :
                            state.mode==="delete" ?
                                "Are you sure you want to delete this?":
                                state.mode==="update" ?
                                    "Update":
                                    "Add New Channel"
                    }
                    open={state.openDialogue}
                    onCancel={() => setState({
                        ...state,
                        openDialogue: false,
                        selectedData: null,
                        mode:""
                    })}
                    onOk={() => {
                        state.mode==="de-activate" ? deActivateFunc() :
                            state.mode==="activate" ? activateFunc() :
                                state.mode==="delete" ? deleteFunc() :
                                    state.mode==="update" ? updateFunc() : saveFunc()
                    }}
                >
                    {
                        (state.mode==="update" || state.mode==="add") &&
                        <>
                            <Input style={{marginTop: 10}} value={state.name}
                                   onChange={(e) => setState({...state, name: e.target.value})}
                                   placeholder="Enter name"/>
                            <Input style={{marginTop: 10}} value={state.accessToken}
                                   onChange={(e) => setState({...state, accessToken: e.target.value})}
                                   placeholder="Enter token"/>
                        </>
                    }
                </Modal>

                <div style={{marginTop:40}}/>
                <Table
                    pagination={pagination}
                    onChange={(page) => {
                        setPagination(page);
                    }}
                    style={{ marginTop: '2%' }}
                    columns={columns}
                    dataSource={updatedArray}
                />
            </div>
    );
}

export default TableContent;
