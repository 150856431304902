import React, { useState, useEffect } from "react";
import "./Workspace.scss";
import { BsFillBuildingsFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import SettingsHeader from "../../ResuseableComponnets/SettingsHeader";
import { Input, Select, Button } from "antd";
import { profileInformation, ChannelListApi } from "./Api/Api";
import axios from "../../../Modules/Authentication/headers";
import openNotificationWithIcon from "../../ResuseableComponnets/Toaster";
import Loader from "../../ResuseableComponnets/Loader";
const WorkspaceData = () => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [state, setState] = useState({
    address: "",
    description: "",
    vertical: "",
    about: "",
    email: "",
    websites: [],
    profile_picture_url: "",
    fileList: [],
    previewVisible: false,
    previewTitle: "",
    previewImage: "",
    Loader: false,
    id: null,
  });

  const DataId = () => {
    ChannelListApi()
      .then((res) => {
        const channelData = res?.data?.data;
        if (channelData && channelData.length > 0) {
          setState((prevState) => ({
            ...prevState,
            id: channelData[0]._id,
          }));
          fetchData(channelData[0]._id);
        }
      })
      .catch((error) => {
        console.error("Error processing channel data:", error);
      });
  };

  const profileData = () => {
    const formData = new FormData();
    const data = {
      address: state.address,
      description: state.description,
      vertical: state.vertical,
      about: state.about,
      email: state.email,
      websites: state.websites,
    };
    formData.append("data", JSON.stringify(data));
    if (state.fileList.length > 0) {
      formData.append("profile_image", state.fileList[0]);
    }

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    if (state.id) {
      axios
        .put(`/channel/wabaUpdate/${state.id}`, formData, config)
        .then((res) => {
          openNotificationWithIcon(
            "success",
            "Success Message",
            res.data?.message
          );
          fetchData(state.id);
        })
        .catch((error) => {
          console.error(error);
          openNotificationWithIcon(
            "error",
            "Error Message",
            "Failed to update workspace"
          );
        });
    }
  };

  const fetchData = (channelId) => {
    setState((prevState) => ({
      ...prevState,
      Loader: true,
    }));
    if (channelId) {
      profileInformation(channelId)
        .then((res) => {
          const data = res?.data?.data;
          setState((prevState) => ({
            ...prevState,
            address: data?.address || "",
            description: data?.description || "",
            vertical: data?.vertical || "",
            about: data?.about || "",
            email: data?.email || "",
            websites: data?.websites || [],
            profile_picture_url: data?.profile_picture_url || "",
            fileList: data?.profile_picture_url
              ? [
                  {
                    uid: "-1",
                    name: "image",
                    status: "done",
                    url: data.profile_picture_url,
                  },
                ]
              : [],
          }));
          setState((prevState) => ({
            ...prevState,
            Loader: false,
          }));
        })
        .catch((error) => {
          console.error(error);
          openNotificationWithIcon(
            "error",
            "Error Message",
            "Failed to fetch workspace data"
          );
        });
    }
  };

  useEffect(() => {
    DataId();
  }, []);

  const options = [
    "other",
    "auto",
    "beauty",
    "apparel",
    "edu",
    "entertain",
    "event_plan",
    "finance",
    "grocery",
    "govt",
    "hotel",
    "health",
    "nonprofit",
    "prof_services",
    "retail",
    "travel",
    "restaurant",
  ];

  const isFormValid = () => {
    return (
      state.address !== "" &&
      state.description !== "" &&
      state.email !== "" &&
      state.vertical !== "" &&
      state.websites.length > 0
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setState((prevState) => ({
          ...prevState,
          previewImage: reader.result,
          fileList: [file],
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };
  const fileInputRef = React.useRef(null);

  return (
    <div className="synctic-module-container-tabs">
      {state.Loader ? (
        <Loader />
      ) : (
        <>
          <div className="whatsapp-container-work">
            <div
              className="cards-box-data"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <BsFillBuildingsFill
                className="icon"
                style={{
                  fontSize: "2.5rem",
                  color: "#007bff",
                  marginRight: "0.75rem",
                }}
              />
              <div>
                <div
                  style={{
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginBottom: "0.25rem",
                  }}
                >
                  Workspace
                </div>
                <div
                  style={{
                    fontSize: "0.875rem",
                    color: "#666",
                  }}
                >
                  Manage-Your-WorkSpace-Settings
                </div>
              </div>
            </div>
            <div className="cards-box-data" style={{ marginTop: "10px" }}>
              <div
                className="profile-pic-container"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="profile-pic-Data"
                  onClick={handleImageClick}
                  style={{
                    cursor: "pointer",
                    width: "130px",
                    height: "130px",
                    border: "1px dashed #d9d9d9",
                    borderRadius: "65px",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                    style={{
                      display: "none",
                    }}
                  />
                  {state.previewImage || state.profile_picture_url ? (
                    <img
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={state.previewImage || state.profile_picture_url}
                    />
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        color: "#666",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "20px",
                          marginBottom: "8px",
                        }}
                      >
                        +
                      </div>
                      <div>Upload Image</div>
                    </div>
                  )}
                </div>
              </div>

              <form style={{ display: "flex", flexDirection: "column" }}>
                <div className="main-name-api">
                  <div className="whatsapp-Heading">Address</div>
                  <Input
                    placeholder="Address"
                    value={state.address}
                    className="api-name-input"
                    size="large"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        address: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="main-name-api">
                  <div className="whatsapp-Heading">Description</div>
                  <TextArea
                    rows={4}
                    placeholder="Description"
                    value={state.description}
                    className="api-name-input"
                    size="large"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        description: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="main-name-api">
                  <div className="whatsapp-Heading">Options</div>
                  <Select
                    style={{ width: "100%", marginTop: "1%" }}
                    value={state.vertical}
                    size="large"
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        vertical: value,
                      }))
                    }
                  >
                    {options.map((option) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div className="main-name-api">
                  <div className="whatsapp-Heading">About</div>
                  <Input
                    placeholder="About"
                    value={state.about}
                    size="large"
                    className="api-name-input"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        about: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="main-name-api">
                  <div className="whatsapp-Heading">Email</div>
                  <Input
                    placeholder="Email"
                    value={state.email}
                    size="large"
                    className="api-name-input"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="main-name-api">
                  <div className="whatsapp-Heading">Websites</div>
                  <div
                    style={{ display: "flex", gap: "10px", marginTop: "1%" }}
                  >
                    <Input
                      placeholder="Website 1"
                      value={state.websites[0] || ""}
                      size="large"
                      className="api-name-input"
                      onChange={(e) => {
                        const newWebsites = [...state.websites];
                        newWebsites[0] = e.target.value;
                        setState((prevState) => ({
                          ...prevState,
                          websites: newWebsites,
                        }));
                      }}
                    />

                    <Input
                      placeholder="Website 2"
                      value={state.websites[1] || ""}
                      size="large"
                      className="api-name-input"
                      onChange={(e) => {
                        const newWebsites = [...state.websites];
                        newWebsites[1] = e.target.value;
                        setState((prevState) => ({
                          ...prevState,
                          websites: newWebsites,
                        }));
                      }}
                    />
                  </div>
                </div>
              </form>

              <div style={{ display: "flex", gap: "10px", marginTop: "2%" }}>
                <Button
                  type="primary"
                  onClick={profileData}
                  style={{
                    margin: "2% auto",
                    width: "30%",
                  }}
                  disabled={!isFormValid()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WorkspaceData;
