import { MoreOutlined }                                    from "@ant-design/icons";
import Button                                              from "@mui/material/Button";
import { Dropdown, Menu, Tag, Modal, Input, Select, Form } from "antd";
import moment                                              from "moment/moment";
import React, { useEffect, useState }                      from "react";
import {
  useTranslation
}                                                          from "react-i18next";
import Loader                                              from '../../../ResuseableComponnets/Loader';
import openNotificationWithIcon                            from '../../../ResuseableComponnets/Toaster';
import {
  GetAllContacts
}                                                          from "../../Api/Api";
import {
  activateGroup,
  addGroup,
  deActivateGroup,
  deleteGroup,
  GetAllGroups,
  getGroupById,
  updateGroup
}                                                          from "../../Api/groupApis";
import Table_contacts                                      from "../Table_contacts";

const { Option } = Select;
export default function Groups() {
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  const { t } = useTranslation()
  const [state, setState] = useState({
    isOpen: false,
    name: "",
    description: '',
    email: "",
    phone: "",
    data: [],
    value: false,
    groupId: "",
    isEmailError: false,
    emailErrorMsg: "",
    isContactInfo: false,
    contactData: "",
    isValueChange: false,
    filterModal: false,
    columnsData: [],
    openDrawer: false,
    contactInfo: null,
    mode: '',
    isDialogueOpen: false,
    contactArray: [],
    selectedContacts: null,
    loader: false,
    users: [],
  });

  const GetAllGroupsFunc = async () => {
    setState({ ...state, loader: true })
    let allGroups = await GetAllGroups()
    if (allGroups) {
      let data = allGroups?.data?.data?.rows
      let totalCount = allGroups?.data?.data?.totalCount
      makeColumnsData(data, totalCount)
    }
  }


  const ActivateGroupFunc = (id) => {
    setState({ ...state, isDialogueOpen: false })
    activateGroup(id)
      .then(() => {
          GetAllGroupsFunc()
        }
      )
      .catch(err => console.log(err, "err"))
  }

  const deActivateGroupFunc = (id) => {
    setState({ ...state, isDialogueOpen: false })
    deActivateGroup(id)
      .then(() => {
          GetAllGroupsFunc()
        }
      )
      .catch(err => console.log(err, "err"))
  }

  const getGroupByIdFunc = async (id) => {
    let response = await getGroupById(id)
    if (!!response?.data?.data) {
      let data = response.data.data[0]
      let contacts = []
      !!data?.contact && data.contact.map(item => contacts.push(item._id))
      setState(prevState => ({
        ...prevState,
        name: data?.name,
        description: data?.description,
        selectedContacts: contacts,
        isDialogueOpen: true,
        mode: 'edit',
        groupId: id,
        users: contacts
      }))
    }
    else {
      openNotificationWithIcon('error', 'Error message', 'Error while fetching group.')
    }
  }
  const deleteGroupFunc = (id) => {
    setState({ ...state, isDialogueOpen: false })
    deleteGroup(id)
      .then(() => {
          GetAllGroupsFunc()
        }
      )
      .catch(err => console.log(err, "err"))
  }

  const makeColumnsData = async (data, totalCount) => {
    let columnsData = data.length > 0 && Object.keys(data[0])
    let indexToRemove = columnsData.indexOf("_id");
    columnsData.splice(indexToRemove, 1);
    columnsData.push("updated_By");
    columnsData.push("actions");
    let updatedArray = !!data && Array.isArray(data) ? data.map((obj) => ({
      ...obj,
      addedBy: obj?.addedBy?.email,
      status: !!obj?.status ? 'Active' : 'De-active',
      created: moment(obj.created).format('MMMM Do YYYY, h:mm a'),
      updated: moment(obj?.updated?.timestamp).format('MMMM Do YYYY, h:mm a'),
      updated_By: !!obj.updated?.user && obj.updated.user.email,
      contact: obj.contact.map(item => <Tag color="magenta" style={ { marginTop: 2 } }> { item.name }</Tag>),
      actions: <Dropdown overlay={ (
        <Menu>
          <Menu.Item onClick={ () => {
            getGroupByIdFunc(obj._id)
          } } key="1">
            Edit
          </Menu.Item>
          <Menu.Item onClick={ () => deleteGroupFunc(obj._id) } key="2">
            Delete
          </Menu.Item>
          <Menu.Item onClick={ () => ActivateGroupFunc(obj._id) } key="3">
            Activate
          </Menu.Item>
          <Menu.Item onClick={ () => deActivateGroupFunc(obj._id) } key="4">
            De-activate
          </Menu.Item>
        </Menu>
      ) } trigger={ ['click'] }>
        <a onClick={ (e) => e.preventDefault() }>
          <MoreOutlined style={ { fontSize: '14px' } }/>
        </a>
      </Dropdown>
    })) : []

    let allContacts = await GetAllContacts()

    setState({
      ...state,
      columnsData: columnsData,
      data: updatedArray,
      isDialogueOpen: false,
      contactArray: !!allContacts ? allContacts?.data?.data : [],
      loader: false
    })

    if (totalCount) {
      setPagination(prevState => ({
        ...prevState, total: totalCount
      }))
    }
  }

  const selectedTeam = (data) => {
    let newData = {
      ...data,
      'name': data.name
    }
    setState(prevState => ({ ...prevState, contactData: newData, isContactInfo: true }))
  }
  const onSubmit = (data) => {
    addGroup(data.data).then(() => {
      GetAllGroupsFunc()
    }).catch((err) => {
      console.log(err)
    })
  }
  const onUpdate = (data) => {
    updateGroup(state.groupId, data.data).then(() => {
      GetAllGroupsFunc()
    }).catch((err) => {
      openNotificationWithIcon('error', 'Error message', err?.response?.data?.message)
    })
  }

  const handleTableChange = (newPagination) => {
    let previousPage = pagination.current;

    let currentPage = newPagination.current;
    const pageSize = newPagination.pageSize;

    if (currentPage > previousPage) {
      // Check if we need to load more data (i.e., we're on the last page)
      const isLastPage = (state?.data?.length % pageSize === 0) && (state?.data?.length < pagination?.total);
      if (isLastPage) {
        const paginationNumber = Math.floor(state?.data?.length / 10) + 1; // Calculate the next page
        // Create the query string for pagination
        let paginationQuery = `pagination=${ paginationNumber }`;
        // Fetch the next page of data
        GetAllGroups(paginationQuery)
          .then(res => {
            // Append the new data to the existing state
            setState(prevState => ({
              ...prevState,
              data: [...prevState.data, ...res?.data?.data?.rows],
            }));
          })
          .catch(err => {
            console.error('Error fetching data:', err);
          });
      }
    }

    // Update the pagination state
    setPagination({
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    });
  };


  useEffect(() => {
    GetAllGroupsFunc()
  }, [state.isContactInfo]);
  return (
    <div style={ { width: "100%" } }>
      <>
        <div className='main-header'>
          <div className='all-contacts' style={ {
            display: "flex",
            justifyContent: "space-between"
          } }>
            <div style={ {
              opacity: ".7",
              fontSize: "20px",
              fontWeight: 600,
              marginLeft: "3%"
            } }>{ t("Groups") }
            </div>
            <div style={ { display: "flex", gap: 10 } }>
              <Button variant="contained"
                      onClick={ () => setState({ ...state, filterModal: true }) }>{ t("Filter") }</Button>
              <Button variant="contained"
                      onClick={ () => {
                        setState({ ...state, mode: 'new', isDialogueOpen: true })
                      } }>{ t("Add new group") }</Button>
            </div>
          </div>
        </div>

        {
          state.loader ? <Loader height={ '100%' }/> : state.columnsData.length > 0 &&
            <Table_contacts
              filterModal={ state.filterModal }
              closeFilterModal={ () => setState({
                ...state,
                filterModal: false
              }) }
              selectedTeam={ selectedTeam }
              tableData={ state.data }
              columns={ state.columnsData }
              pagination={ pagination }
              handleTableChange={ handleTableChange }
            />
        }
      </>
      <Modal
        title={ state.mode === 'new' ? `Create group` : 'Update group' }
        open={ state.isDialogueOpen }
        footer={ false }
        destroyOnClose={ () => setState(prevState => ({
          ...prevState,
          isDialogueOpen: false,
          selectedContacts: null,
          name: '',
          description: '',
          users: []
        })) }
        onCancel={ () => setState(prevState => ({
          ...prevState,
          isDialogueOpen: false,
          selectedContacts: null,
          name: '',
          description: '',
          users: []
        })) }
      >
        <Form
          name="nest-messages"
          layout="vertical"
          onFinish={ state.mode === 'new' ? onSubmit : onUpdate }
          style={ {
            maxWidth: 600,
          } }
          initialValues={ {
            data: {
              ...state
            }
          } }
        >
          <Form.Item
            name={ ['data', 'name'] }
            label="Name"
            rules={ [
              {
                required: true,
                message: "Name is important"
              },
            ] }
          >
            <Input placeholder={ "Name" }/>
          </Form.Item>
          <Form.Item name={ ['data', 'description'] } label="Description">
            <Input.TextArea placeholder={ 'Description' }/>
          </Form.Item>
          <Form.Item
            name={ ['data', 'users'] }
            label="Users"
            rules={ [
              {
                required: true,
                message: 'Select atleast one user',
                type: 'array',
              }
            ] }
          >
            <Select mode="multiple" placeholder="Please select Users">
              {
                state?.contactArray.length > 0 && state.contactArray.map(obj => <Option
                  value={ obj.id }>{ obj.name }</Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item
          >
            <Button style={ { float: 'right' } } type="primary" htmlType="submit" variant="contained">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
