import React, { useState, useEffect } from "react";
import Cards from "../reuseableComponents/Cards";
import "../overview/overview.scss";
import "./AgentPerformance.scss";
import { Checkbox, Modal, Button, Table, Tabs } from "antd";
import AgentsFilterTable from "./AgentsFilterTable";
import {
  loginActivity,
} from "../Api/Api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AllAgentPerformance , getLoginActivity  } from "../../states/actions";
import moment from "moment";
import { useTranslation } from "react-i18next";
// import PaiChart from "./PaiChart"

const AgentPerformance = (props) => {
  const {t} = useTranslation()
  const [state, setState] = useState({
    tCustomers: false,
    mac: false,
    mode: "",
    whatsapp: "",
    instagram: "",
    messenger: "",
    currTabOne:1,
    currTabPageSizeOne:4,
    currTabTwo:1,
    currTabPageSizeTwo:4,
  });
  const dispatch = useDispatch();
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 2,
  });
  const [paginationTwo, setPaginationTwo] = React.useState({
    current: 1,
    pageSize: 2,
  });
  const assigned = useSelector((state) => state.dashboard?.Agent?.assigned);
  const progress = useSelector((state) => state.dashboard?.Agent?.progress);
  const resolutionTime = useSelector(
    (state) => state.dashboard?.Agent?.resolutionTime
  );
  const responseTime = useSelector((state) => state.dashboard?.Agent?.responseTime);
  const solved = useSelector((state) => state.dashboard?.Agent?.solved);
  const agentMessage = useSelector((state) => state.dashboard?.Agent?.agentMessage);
  const agent = useSelector((state) => state.dashboard?.Agent?.agentList || []);
  const Login = useSelector((state) => state.dashboard?.Activity || []);

  const fatchlogin = () => {
    loginActivity()
      .then((res) => {
        dispatch(getLoginActivity(res?.data?.data));
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    dispatch(AllAgentPerformance())
    fatchlogin();
  }, []);

  let { title, value, isModalOpen, mode, modalOpenFunc } = props;
  const modalFooter = [
    <Button key="cancel" onClick={() => modalOpenFunc()}>
      Cancel
    </Button>,
    <Button key="apply" onClick={() => modalOpenFunc()} primary>
      Apply
    </Button>,
  ];
  const AgentsAvailibilityColumns = [
    {
      title: t("Agent Name"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Login Time"),
      dataIndex: "loginTime",
      key: "loginTime",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
    },
  ];
  const AgentsBoardColumns = [
    {
      title: t("Agent"),
      dataIndex: "emailData",
      key: "emailData",
    },
    {
      title: t("In Progress"),
      dataIndex: "inProgressCount",
      key: "inProgressCount",
    },
    {
      title: t("Assigned"),
      dataIndex: "agentName",
      key: "agentName",
    },
    {
      title: t("Solved"),
      dataIndex: "solvedCount",
      key: "solvedCount",
    },
    {
      title: t("Avg. Response Time"),
      dataIndex: "avgResponseTime",
      key: "avgResponseTime",
    },
    {
      title: t("Avg. Resolution Time"),
      dataIndex: "avgResolutionTime",
      key: "avgResolutionTime",
    },
  ];
  const data = agent.map((agent, index) => ({
    key: index,
    agentName: agent.assignedCount,
    emailData: agent.email,
    inProgressCount: agent.inProgressCount ,
    avgResponseTime: agent.responseTime,
    avgResolutionTime: agent.resolutionTime,
    solvedCount:agent.solvedCount
  }));

  const AgentsAvailability = Login.map((item, index) => ({
    key: index,
    email: item.email,
    id: item.id,
    loginTime: item.loginTime
      ? moment(agent.accepted).format("MMMM Do YYYY, h:mm a")
      : "",
    status: item.status,
  }));

  const AgentsAvailibilityData = [];
  const items = [
    {
      key: "1",
      label: "Channels",
      children: (
        <div>
          <div style={{ fontWeight: "550" }}>Channel</div>
          <div style={{ marginTop: 6 }} />
          <Checkbox
            checked={state.whatsapp}
            onChange={() => setState({ ...state, whatsapp: !state.whatsapp })}
          >
            Whatsapp
          </Checkbox>
          <div style={{ marginTop: 4 }} />
          <Checkbox
            checked={state.messenger}
            onChange={() => setState({ ...state, messenger: !state.messenger })}
          >
            Messenger
          </Checkbox>
          <div style={{ marginTop: 4 }} />
          <Checkbox
            checked={state.instagram}
            onChange={() => setState({ ...state, instagram: !state.instagram })}
          >
            Instagram
          </Checkbox>
          <div style={{ marginTop: 4 }} />
        </div>
      ),
    },
    {
      key: "2",
      label: "Agents",
      children: <AgentsFilterTable />,
    },
  ];

  return (
    <div>
      <div className="count-card-container">
        <Cards title={t("Assigned")} value={assigned || 0} />
        <Cards title={t("In Progress")} value={progress || 0} />
        <Cards title={t("Solved By Agent")} value={solved || 0} />
        <Cards title={t("Messages Sent")} value={agentMessage || 0} />
        <Cards title={t("Avg. Response Time")} value={responseTime || 0} />
        <Cards title={t("Avg. Resolution Time")} value={resolutionTime || 0} />
      </div>
      <div className={"agents-availability-container"}>
        <div className={"cards-box"} style={{ width: "22%" }}>
        <h6>{t("Agents Availability")}</h6>
        {/*<PaiChart/>*/}
        </div>
        <div className={"cards-box"} style={{ width: "78%" }}>
          <h6>{t("Agents Availability")}</h6>
          <div className="Agents-Availability">
          <Table
            columns={AgentsAvailibilityColumns}
            pagination={pagination}
            onChange={(page) => {
              setPagination(page)
            }}
            dataSource={AgentsAvailability}
            style={{ marginTop: 24 }}
          />
          </div>
        </div>
      </div>
      <div
        className={"cards-box"}
        style={{
          width: "100%",
          marginTop: 10,
        }}
      >
        <h6>{t("Agents Board")}</h6>
        <Table
          pagination={paginationTwo}
          onChange={(page) => {
            setPaginationTwo(page)
          }}
          columns={AgentsBoardColumns}
          dataSource={data}
          style={{ marginTop: 24 }}
          
        />
      </div>
      <Modal
        title={
          mode === "export"
            ? "Export Insights"
            : mode === "export" && "Filter Agent Performance"
        }
        open={isModalOpen}
        onCancel={() => {
          modalOpenFunc();
          setState({
            ...state,
            mac: false,
            tCustomers: false,
            whatsapp: false,
            instagram: false,
            messenger: false,
          });
        }}
        footer={modalFooter}
        closable={() => {
          modalOpenFunc();
          setState({
            ...state,
            mac: false,
            tCustomers: false,
            whatsapp: false,
            instagram: false,
            messenger: false,
          });
        }}
      >
        {state.mode === "export" ? (
          <div>
            <div style={{ fontWeight: "550" }}>Include breakdown by</div>
            <div style={{ marginTop: 6 }} />
            <Checkbox
              checked={state.mac}
              onChange={() => setState({ ...state, mac: !state.mac })}
            >
              Agent Board Summary
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.tCustomers}
              onChange={() =>
                setState({ ...state, tCustomers: !state.tCustomers })
              }
            >
              Agent Board Details
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.tCustomers}
              onChange={() =>
                setState({ ...state, tCustomers: !state.tCustomers })
              }
            >
              Agent Away Logs
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.tCustomers}
              onChange={() =>
                setState({ ...state, tCustomers: !state.tCustomers })
              }
            >
              Agent Login Logs
            </Checkbox>
          </div>
        ) : (
          mode === "filter" && (
            <Tabs defaultActiveKey="1" tabPosition={"top"} items={items} />
          )
        )}
      </Modal>
    </div>
  );
};

export default AgentPerformance;
