import ArrowDropDownIcon              from "@mui/icons-material/ArrowDropDown";
import './Inbox.scss';
import Avatar                         from "@mui/material/Avatar";
import Button                         from '@mui/material/Button';
import Dialog                         from "@mui/material/Dialog";
import DialogTitle                    from "@mui/material/DialogTitle";
import List                           from '@mui/material/List';
import ListItem                       from '@mui/material/ListItem';
import ListItemText                   from '@mui/material/ListItemText';
import TextField                      from "@mui/material/TextField";
import Typography                     from '@mui/material/Typography';
import { Dropdown, Menu, Tag }        from "antd";
import { MuiTelInput }                from "mui-tel-input";
import React, { useEffect, useState } from "react";
import { useTranslation }             from "react-i18next";
import { useSelector }                from 'react-redux';
import WhatsappImage                  from "../../assets/svg/social-logos/whatsappLogo.png";
import Loader                         from '../ResuseableComponnets/Loader';
import { readAllMessages }            from './Apis';
import ActionsCreators                from './states';

const AllConversations = (props) => {
    const { t } = useTranslation()
    const [state, setState] = useState({
        data: [],
        newMessage: false,
        activeValue: "Active Chats",
        isOpen: false,
        phone: "",
        selectedId: "",
        conversationsProfiles: [],
        activeStatus: "",
        channel: 'WhatsApp',
        currsorting: 'Newest'
    })

    let conversations = useSelector(state => state.inbox.allWhatsappConversations)
    const getAllConversationFunc = () => {
        if(conversations.length > 0) {
            if(props.activeTab === "forward") {
                return conversations
            }
            if (props.activeTab === "all") {
                return conversations
            } else if(props.activeTab === "open"){
                return conversations.filter(item=>item.status === "open")
            } else if(props.activeTab === "solved"){
                return conversations.filter(item=>item.status === "closed")
            } else if(props.activeTab === "in-progress"){
                return conversations.filter(item=>item.status === "in-progress")
            } else if(props.activeTab === "waiting"){
                return conversations.filter(item=>item.status === "waiting")
            } else if(props.activeTab === "spam"){
                return conversations.filter(item=>item.status === "spam")
            } else if(props.activeTab === "exit"){
                return conversations.filter(item=>item.status === "exit")
            } else if(props.activeTab === "forward"){
                return conversations.filter(item=>item.status === "forward")
            }
        } else return []
    }

    let filteredConversations = props.isBotInbox ? conversations : getAllConversationFunc()

    let filteredMenuItems = state.currsorting === 'Newest' ?
      filteredConversations?.length > 0 && filteredConversations.sort((a, b) => new Date(b?.lastCustomerMessage) - new Date(a?.lastCustomerMessage)) :
      filteredConversations?.length > 0 && filteredConversations.sort((a, b) => new Date(a?.lastCustomerMessage) - new Date(b?.lastCustomerMessage))

    let allConversations = []

    useEffect(() => {
        allConversations = filteredConversations
        setState({ ...state, conversationsProfiles: allConversations, activeValue: 'All', channel: props.channel })
    }, [filteredConversations?.length]);

    useEffect(() => {
        setState({ ...state, selectedId: '' })
    }, [props.filterName]);


    return <div className="allconversation-main">
        <div style={ {
            marginTop: 16,
            width: '100%'
        } }>
            <div style={ {
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                overflow: 'hidden'
            } }>
                <div style={ {
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    fontSize: 16
                } }>
                    <img style={ { width: 18 } } src={ WhatsappImage } alt="Whatsapp"/>
                    <b>{ t("Whatsapp") }</b>
                </div>
                <div>
                    <Dropdown overlay={ (
                      <Menu>
                          <Menu.Item key="1">
                              <div style={ { overflow: "hidden" } } onClick={ () => {
                                  setState({
                                      ...state,
                                      currsorting: 'Newest'
                                  })
                              }
                              }>
                                  { t("Newest") }
                              </div>
                          </Menu.Item>
                          <Menu.Item key="2">
                              <div onClick={ () => {
                                  setState({
                                      ...state,
                                      currsorting: 'Oldest'
                                  })
                              }
                              }>
                                  { t("Oldest") }
                              </div>
                          </Menu.Item>
                      </Menu>
                    ) } trigger={ ['click'] }>
                        <a onClick={ (e) => e.preventDefault() }>
                            <div style={ {
                                fontWeight: 550,
                                width: "100%",
                                opacity: .7,
                                textAlign: 'right'
                            } }>{ state.currsorting } <span><ArrowDropDownIcon/></span></div>
                        </a>
                    </Dropdown>
                </div>
            </div>
            <div style={ {
                fontWeight: 550,
                width: "100%",
                opacity: .7,
                textAlign: 'right',
                overflow: "hidden"
            } }>Chats { filteredConversations && filteredConversations?.length ? filteredConversations.length : 0 }</div>
            <Dialog
              onClose={ () => setState({
                  ...state,
                  isOpen: false,
                  name: "",
                  contactId: "",
                  email: "",
                  phone: "",
                  isEmailError: false,
                  emailErrorMsg: ""
              }) }
              open={ state.isOpen }
            >
                <DialogTitle>{ t("Add Contact") }</DialogTitle>
                <div style={ { display: "flex", flexDirection: "column", gap: 10, padding: 30, paddingTop: 10 } }>
                    <MuiTelInput
                      required
                      className={ 'textField' }
                      size="small"
                      id="outlined-required"
                      defaultCountry="PK"
                      value={ state.phone }
                      style={ { width: 300 } }
                      onChange={ (e) => {
                          setState({ ...state, phone: e })
                      } }
                    />
                    <div style={ { textAlign: "center" } }>{ t("Or") }</div>
                    <TextField
                      id="outlined-size-small"
                      placeholder="Search Contact"
                      size="small"
                      style={ { width: 300 } }
                    />
                    <Button variant="contained">{ t("Next") }</Button>
                </div>
            </Dialog>
        </div>
        <div className={ 'all-conversation-conatiner' }>
            {
                props.allConversationsLoader ? <Loader message={ 'Getting all chats' } height={ '100%' }/> :

                  filteredMenuItems.length > 0 ?
                    <List className='all-conversation' data-aos="fade-up" sx={ {
                        bgcolor: 'background.paper',
                        height: 'fit-content'
                    } }>
                        {
                          !!filteredMenuItems && filteredMenuItems.map((item, idx) => <div
                              key={ idx }
                              onClick={ () => {
                                  setState({ ...state, selectedId: item.conversationId })
                                  props.getChatInformation(item.conversationId, true)
                                  props.dispatch(ActionsCreators.deleteWhatsappConversationAction({
                                      conversationId: item.conversationId
                                  }))
                                  readAllMessages(item.conversationId).then(() => console.log()).catch(() => console.log())
                              } }
                              style={ { cursor: "pointer" } }
                            >
                                <div style={ {
                                    background: state.selectedId === item.conversationId ?
                                      "rgba(66, 135, 245, .1)" : ""
                                } }
                                     className="allConversationMenu">
                                    <ListItem alignItems="flex-start">
                                        <div style={ { position: "relative" } }>
                                            <Avatar alt={
                                                props.channel === "messenger" ?
                                                  item.chatInitiator.first_name :
                                                  item.chatInitiator?.name
                                            } src={
                                                props.channel === "messenger" ?
                                                  item.chatInitiator.profile_pic : "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEBwzA9VEjgBydUx2ZTVWaWKx2abMJ5daAAXmSqfl9evKnaV4Rer_PkKz_r8_m76Ph0wOd-yjd5&psid=7436819382996141&width=1024&ext=1704237886&hash=AfqcnOSUP1eLiEWW0GZvImCJkOMvK1iMJ2HDICr6RYePzg"
                                            }/>
                                        </div>
                                        <img style={ {
                                            height: 12,
                                            width: 12,
                                            marginTop: "10%",
                                            marginLeft: "-11px",
                                            zIndex: 1
                                        } } src={ props.channelLogo }
                                             alt="whatappImg"/>
                                        <ListItemText
                                          primary={
                                              <div style={ { marginLeft: 10 } }>
                                                  <div
                                                    style={ {
                                                        fontSize: 14,
                                                        opacity: .9,
                                                        fontWeight: 500
                                                    } }>{
                                                      props.channel === "whatsapp" ?
                                                        item.chatInitiator?.name :
                                                        props.channel === "instagram" ? item.chatInitiator?.username :
                                                          item.chatInitiator?.first_name + " " + item.chatInitiator?.last_name
                                                  }
                                                  </div>
                                                  <React.Fragment>
                                                      <Typography
                                                        sx={ {
                                                            display: 'inline',
                                                        } }
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                      >
                                                          {
                                                              props.channel === "whatsapp" ? <span style={ {
                                                                    marginLeft: 4,
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center'
                                                                } }>
                                                        <div>
                                                            { item.chatInitiator?.phone }
                                                        </div>
                                                        <div style={ {
                                                            display: 'flex',
                                                            gap: 4
                                                        } }>
                                                            <Tag color="orange">{ item.status }</Tag>
                                                            {
                                                              !!item?.unreadMessages && !props.isBotInbox &&
                                                              <Tag color="red">{ item?.unreadMessages }</Tag>
                                                            }
                                                        </div>
                                                    </span>
                                                                : <span style={ { marginLeft: 4 } }>
                                                            <span
                                                              style={ { fontWeight: 600, } }>ID: </span> { item?.chatInitiator?.sender_id }
                                                        </span>
                                                          }
                                                      </Typography>
                                                  </React.Fragment>
                                              </div>
                                          }
                                        />
                                    </ListItem>
                                </div>
                            </div>
                          )
                        }
                    </List> : <h4 style={ {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        opacity: .7
                    } }>{ t("Nothing to show") }</h4>
            }
        </div>
    </div>
}

export default AllConversations