import { Radio, Button, TimePicker, Checkbox } from "antd";
import SettingsHeader from "../../ResuseableComponnets/SettingsHeader";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {getBusinessHours , saveBusinessHours} from "./API/Api"
import openNotificationWithIcon from "../../../Modules/ResuseableComponnets/Toaster";


const Businesshour = () => {
  const { t } = useTranslation();
  const Weekdays = [
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
    t("Sunday"),
  ];

  const [state, setState] = useState({
    allDay: null,
    perDays: {
      monday: {
        startTime: "",
        endTime: "",
      },
      tuesday: {
        startTime: "",
        endTime: "",
      },
      wednesday: {
        startTime: "",
        endTime: "",
      },
      thursday: {
        startTime: "",
        endTime: "",
      },
      friday: {
        startTime: "",
        endTime: "",
      },
      saturday: {
        startTime: "",
        endTime: "",
      },
      sunday: {
        startTime: "",
        endTime: "",
      },
    },
    isAllDays: true,
  });

  console.log(state.perDays,8376535)


  const CreateTime = () => {
    let AllDatta;
    if (state.isAllDays) {
      if (!state.allDay?.startTime || !state.allDay?.endTime) {
        console.error("Please select both start and end times");
        return;
      }
  
      AllDatta = Object.fromEntries(
        Object.keys(state.perDays).map(day => [
          day,
          {
            startTime: state.allDay.startTime,
            endTime: state.allDay.endTime
          }
        ])
      );
    } else {
      AllDatta = {};
      for (const [day, times] of Object.entries(state.perDays)) {
        if (times.startTime || times.endTime) {
          AllDatta[day] = {
            startTime: times.startTime || dayjs().startOf('day').toISOString(),
            endTime: times.endTime || dayjs().endOf('day').toISOString()
          };
        }
      }
    }
  
    saveBusinessHours(AllDatta)
      .then((res) => {
 if (res.data && res.data.message) {
          openNotificationWithIcon(
            "success",
            "Success Message",
            res.data.message
          );
        }      })
      .catch((error) => {
              console.error("Error creating team:", error);
              openNotificationWithIcon(
                "error",
                "Error Message",
                error.response?.data?.message || "Please try again later."
              );
            });
  };

  const fetchData = () => {
    getBusinessHours()
      .then((res) => {
        setState(prevState => {
          const newState = { ...prevState };
          Object.entries(res).forEach(([day, times]) => {
            if (day in newState.perDays) {
              newState.perDays[day] = {
                startTime: times.startTime || "",
                endTime: times.endTime || ""
              };
            }
          });
          const firstDay = res[Object.keys(res)[0]];
          const allSameTime = Object.values(res).every(time => 
            time.startTime === firstDay.startTime && 
            time.endTime === firstDay.endTime
          );
  
          if (allSameTime) {
            newState.allDay = {
              startTime: firstDay.startTime,
              endTime: firstDay.endTime
            };
          }
  
          return newState;
        });
      })
      .catch((err) => {
        console.error("Error fetching business hours:", err);
      });
  };

  

  useEffect(()=>{
    fetchData()
  },[])

  const handleAllDaysChange = (key, time) => {
    setState((prev) => ({
      ...prev,
      allDay: {
        ...prev.allDay,
        [key]: time ? time.toISOString() : null,
      },
    }));
  };

  const handlePerDayChange = (day, key, time) => {
    setState((prev) => ({
      ...prev,
      perDays: {
        ...prev.perDays,
        [day]: {
          ...prev.perDays[day],
          [key]: time ? time.toISOString() : null,
        },
      },
    }));
  };

  const toggleView = (value) => {
    setState((prev) => ({
      ...prev,
      isAllDays: value === "allDays",
    }));
  };

  return (
    <div className="synctic-module-container">
      <SettingsHeader
        heading={t("Businesshours")}
        description={t("EnableBusinesshours-dis")}
      />
      <div className="cards-box" style={{ marginTop: "10px" }}>
        <Radio.Group
          style={{ display: "flex", gap: "4%", marginTop: "2%" }}
          onChange={(e) => toggleView(e.target.value)}
          value={state.isAllDays ? "allDays" : "perDays"}
        >
          <Radio style={{ fontWeight: "500" }} value="allDays">
            {t("AllDays")}
          </Radio>
          <Radio style={{ fontWeight: "500" }} value="perDays">
            {t("PerDays")}
          </Radio>
        </Radio.Group>

        {state.isAllDays ? (
          <div style={{ 
            display: "flex",
            alignItems: "flex-start",
            gap: "23px", 
            marginTop: "21px",
            padding: "2px",
          }}>
              <div style={{ 
                display: "flex", 
                flexDirection: "column",
                minWidth: "180px",
                gap: "8px"
              }}>              <div style={{ fontWeight: "600" }}>{t("StartTime")}</div>
              <TimePicker
                 style={{ width: "100%" }}
                value={state.allDay ? dayjs(state.allDay.startTime) : null}
                onChange={(time) => handleAllDaysChange("startTime", time)}
              />
            </div>
            <div style={{ 
                display: "flex", 
                flexDirection: "column",
                minWidth: "180px",
                gap: "8px",
              }}>              <div style={{ fontWeight: "600" }}>{t("EndTime")}</div>
              <TimePicker
              style={{ width: "100%" }}
                value={state.allDay ? dayjs(state.allDay.endTime) : null}
                onChange={(time) => handleAllDaysChange("endTime", time)}
              />
            </div>
          </div>
        ) : (
          Weekdays.map((day, index) => (
            <div 
              key={index} 
              className="day-row"
              style={{ 
                display: "flex",
                alignItems: "flex-start",
                gap: "23px", 
                marginTop: "21px",
                padding: "2px",
              }}
            >
              {/* Day and Start Time Column */}
              <div style={{ 
                display: "flex", 
                flexDirection: "column",
                minWidth: "180px",
                gap: "8px"
              }}>
                <div style={{ 
                  fontWeight: "500",
                  fontSize: "15px",
                  marginBottom: "4px"
                }}>
                  {day}
                </div>
                <div style={{ fontWeight: "600" }}>
                  {t("StartTime")}
                </div>
                <TimePicker
                  style={{ width: "100%" }}
                  value={
                    state.perDays[Object.keys(state.perDays)[index]].startTime
                      ? dayjs(state.perDays[Object.keys(state.perDays)[index]].startTime)
                      : state.allDay
                      ? dayjs(state.allDay.startTime)
                      : null
                  }
                  onChange={(time) =>
                    handlePerDayChange(Object.keys(state.perDays)[index], "startTime", time)
                  }
                />
              </div>
          
              {/* End Time Column */}
              <div style={{ 
                display: "flex", 
                flexDirection: "column",
                minWidth: "180px",
                gap: "8px",
                marginTop: "32px" 
              }}>
                <div style={{ fontWeight: "600" }}>
                  {t("EndTime")}
                </div>
                <TimePicker
                  style={{ width: "100%" }}
                  value={
                    state.perDays[Object.keys(state.perDays)[index]].endTime
                      ? dayjs(state.perDays[Object.keys(state.perDays)[index]].endTime)
                      : state.allDay
                      ? dayjs(state.allDay.endTime)
                      : null
                  }
                  onChange={(time) =>
                    handlePerDayChange(Object.keys(state.perDays)[index], "endTime", time)
                  }
                />
              </div>
            </div>
          ))
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            style={{ marginTop: "3%", backgroundColor: "#10163a" }}
            onClick={CreateTime}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Businesshour;
