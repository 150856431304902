import React, { useEffect, useState, useRef } from "react";
import "./Teams.scss";
import { SiMicrosoftteams } from "react-icons/si";
import { Table, Dropdown, Menu, Modal, Input, Form, Button } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import {
  getAllTeams,
  createTeam,
  deleteTeam,
  activateTeam,
  suspendTeam,
  activatedAll,
} from "./API/API";
import { Link } from "react-router-dom";
import { MdEdit, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import openNotificationWithIcon from "../../../Modules/ResuseableComponnets/Toaster";
import { TiTick } from "react-icons/ti";
import { Select, Space } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { ImCross } from "react-icons/im";
import { Tag } from "antd";
import Loader from "../../../Modules/ResuseableComponnets/Loader";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
const Teams = () => {
  const [state, setState] = useState({
    name: "",
    isDialogOpen: false,
    allTeams: [],
    filterName: "Please Select",
    Loader: false,
    error: "",
  });
  const { Option } = Select;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 4,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const showModal = () => {
    setState((prevState) => ({
      ...prevState,
      isDialogOpen: true,
    }));
  };

  const handleCancel = () => {
    setState((prevState) => ({
      ...prevState,
      isDialogOpen: false,
    }));
  };

  const CreateTeamFunc = () => {
    if (!state.name.trim()) {
      setState((prevState) => ({
        ...prevState,
        error: "Please fill in the field.",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        error: "",
      }));
    }

    const data = {
      name: state.name,
      description: "hhhh",
    };
    createTeam(data)
      .then((res) => {
        navigate("/Settings/Teams/TeamInfo");
        if (res.data && res.data.message) {
          openNotificationWithIcon(
            "success",
            "Success Message",
            res.data.message
          );
        }
      })
      .catch((error) => {
        console.error("Error creating team:", error);
        openNotificationWithIcon(
          "error",
          "Error Message",
          error.response?.data?.message || "Please try again later."
        );
      });
  };

  const GetAllfunc = () => {
    setState((prevState) => ({
      ...prevState,
      Loader: true,
    }));
    getAllTeams()
      .then((res) => {
        const Alldata =
          res.data?.data?.map((item, index) => ({
            key: index,
            name: item.name,
            _id: item._id,
            description: item.description,
            status: item.status,
            users: item.users,
            id: item._id,
          })) || [];
        setState((prevState) => ({
          ...prevState,
          allTeams: Alldata,
          Loader: false,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setState((prevState) => ({
          ...prevState,
          Loader: false,
        }));
      });
  };

  const DeleteFunc = (id) => {
    setState((prevState) => ({
      ...prevState,
      Loader: true,
    }));
    deleteTeam(id)
      .then((res) => {
        GetAllfunc();
        if (res.data && res.data.message) {
          openNotificationWithIcon(
            "success",
            "Success Message",
            res.data.message
          );
        }
        setState((prevState) => ({ ...prevState, Loader: false }));
      })
      .catch((error) => {
        console.error("Error deleting team:", error);
        setState((prevState) => ({ ...prevState, Loader: false }));
      });
  };

  const ActiveFunc = (id) => {
    setState((prevState) => ({
      ...prevState,
      Loader: true,
    }));
    activateTeam(id)
      .then((res) => {
        GetAllfunc();
        if (res.data && res.data.message) {
          openNotificationWithIcon(
            "success",
            "Success Message",
            res.data.message
          );
        }
      })
      .catch((error) => {
        console.error("Error in activating team:", error);
        openNotificationWithIcon(
          "error",
          "Error Message",
          error.response?.data?.message || "Please try again later."
        );
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, Loader: false }));
      });
  };

  const SuspendFunc = (id) => {
    setState((prevState) => ({
      ...prevState,
      Loader: true,
    }));
    suspendTeam(id)
      .then((res) => {
        GetAllfunc();
        if (res.data && res.data.message) {
          openNotificationWithIcon(
            "success",
            "Success Message",
            res.data.message
          );
        }
      })
      .catch((error) => {
        console.error("Error in suspending team:", error);
        openNotificationWithIcon(
          "error",
          "Error Message",
          error.response?.data?.message || "Please try again later."
        );
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, Loader: false }));
      });
  };

  const ActivetedFunc = () => {
    setState((prevState) => ({
      ...prevState,
      Loader: true,
    }));
    activatedAll()
      .then((res) => {
        const Alldata =
          res.data?.data?.map((item, index) => ({
            key: index,
            name: item.name,
            status: item.status,
            users: item.users,
          })) || [];
        if (res.data && res.data.message) {
          openNotificationWithIcon(
            "success",
            "Success Message",
            res.data.message
          );
        }
        setState((prevState) => ({
          ...prevState,
          allTeams: Alldata,
        }));
      })
      .catch((error) => {
        console.error("Error in activating all teams:", error);
        openNotificationWithIcon(
          "error",
          "Error Message",
          error.response?.data?.message || "Please try again later."
        );
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, Loader: false }));
      });
  };
  useEffect(() => {
    GetAllfunc();
  }, []);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) =>
        status ? <Tag color="blue">true</Tag> : <Tag color="red">false</Tag>,
    },
    {
      title: "Members",
      dataIndex: "users",
      key: "users",
      render: (users) => {
        const memberCount = users?.length || 0;
        return `${memberCount}`;
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <Link to={`/Settings/Teams/TeamInfo/${record._id}`}>
                  <div>
                    <MdEdit style={{ fontSize: "15px" }} /> Edit
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item key="2">
                <div onClick={() => DeleteFunc(record._id)}>
                  <MdDelete style={{ fontSize: "15px" }} /> Delete
                </div>
              </Menu.Item>
              <Menu.Item key="3">
                <div onClick={() => ActiveFunc(record._id)}>
                  <TiTick style={{ fontSize: "18px" }} /> Active
                </div>
              </Menu.Item>
              <Menu.Item key="4">
                <div onClick={() => SuspendFunc(record._id)}>
                  <ImCross style={{ fontSize: "10px" }} /> Suspend
                </div>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <MoreOutlined style={{ fontSize: "14px" }} />
          </a>
        </Dropdown>
      ),
    },
  ];
  const items = [
    {
      key: "Create",
      label: "Create Team",
      onClick: () => {
        showModal();
        setState((prevState) => ({ ...prevState, filterName: "Create Team" }));
      },
    },
    {
      key: "Activeted",
      label: "Activeted",
      onClick: () => {
        ActivetedFunc();
        setState((prevState) => ({ ...prevState, filterName: "Activeted" }));
      },
    },
    {
      key: "Clear",
      label: "Clear",
      onClick: () => {
        GetAllfunc();
        setState((prevState) => ({ ...prevState, filterName: "Clear" }));
      },
    },
  ];
  return (
    <div className="synctic-module-container">
      <div className="cards-box"  style={{
    padding: "15px",
    borderRadius: "12px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="synctic-module-def">
            <SiMicrosoftteams
              className="icon"
              style={{
                fontSize: "32px",
                color: "#0078d4",
                marginRight: "10px",
              }}
            />
            <div
              className="heading"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              Teams
            </div>
            <div
              className="sub-heading"
              style={{ fontSize: "14px", marginTop: "4px" }}
            >
              Group Your in Teams, manage teams permission and assignment rules.
            </div>
          </div>

          <Dropdown menu={{ items }} placement="bottom" trigger={["click"]}>
            <Button>
              {state.filterName || "Please Select"} <CaretDownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
      <Modal
        title="Create Team"
        visible={state.isDialogOpen}
        onOk={CreateTeamFunc}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Close"
      >
        <Form.Item name="field">
          <Input
            placeholder="Enter Team Name"
            style={{ marginTop: "2%" }}
            value={state.name}
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
          {state.error && (
            <div style={{ fontWeight: 500, color: "red" }}>{state.error}</div>
          )}
        </Form.Item>
      </Modal>
      {state.Loader ? (
        <Loader />
      ) : (
        <Table
          style={{ marginTop: "2%" }}
          columns={columns}
          dataSource={state.allTeams}
          pagination={pagination}
          onChange={(page) => {
            setPagination(page);
          }}
        />
      )}
    </div>
  );
};
export default Teams;
